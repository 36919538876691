.cause-goal {
  background-color: #ffffff;
  border-radius: 10px;
  height: 100px;
  width: 100%;
  margin-right: 10px;
  margin-top: 1rem;
}

.cause-goal .goal-details {
  display: flex;
}

.cause-goal .goal-icon {
  height: 60px;
  width: 60px;
  margin-top: 20px;
  margin-left: 20px;
  flex: 20%;
}

.cause-goal .goal-details .goal {
  flex: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1rem;
  margin-top: 15px;
}

.cause-goal .goal-details .goal .goal-amount .goal-heading {
  font-size: 16px;
  text-transform: uppercase;
  color: var(--darkGray);
  margin-bottom: 5px;
}

.cause-goal .goal-details .goal .goal-amount .goal-limit {
  font-size: 24px;
  font-weight: 600;
}


