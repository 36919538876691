.Topbar {
  padding: 8px 20px;
  margin: 15px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.Topbar .box1 {
  display: flex;
  flex-direction: row;
}

.Title {
  text-transform: capitalize;
}

.mobile-title {
  display: none;
}

.welcome-note {
  font-weight: normal;
  font-size: 18px;
  text-transform: capitalize;
}

.welcome-note span {
  font-weight: 600;
  font-size: 36px;
}

.Topbar .Topbar-SwitchBtn {
  border-radius: 30px;
  z-index: 1;
}

.Topbar .Topbar-SwitchBtn .switch-handle {
  width: 45px;
  border-radius: 20px;
}

.Topbar .Topbar-SwitchBtn .switch-on {
  background-color: #2196f3 !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.Topbar .Topbar-SwitchBtn .switch-off {
  background-color: #ccc !important;
  text-indent: 150%;
  white-space: nowrap;
  overflow: hidden;
}

.Topbar .box2 {
  display: flex;
  align-items: center;
  align-content: center;
}

.Topbar .box2 .dashboard-mode {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 10px;
}

.Topbar .box2 .dashboard-mode .switch-dashboard {
  font-size: 16px;
  color: var(--dashboardActive);
  margin: 0 5px 0 5px;
}

.Topbar .box2 .dashboard-mode .mode-image {
  width: 60px;
  height: 60px;
}

.Topbar .box2 .topbar-right {
  display: flex;
  /* align-items: center;  */
  flex-direction: row;
}

.Topbar .box2 .topbar-right .notifications {
  width: 52px;
  height: 52px;
  background-color: #ffffff;
  border-radius: 32px;
}

.Topbar .box2 .topbar-right .notification-count {
  position: relative;
  top: -8px;
  left: 50px;
  width: 18px;
  height: 18px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffb739;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
}

.Topbar .box2 .topbar-right .notifications .bell-icon {
  position: relative;
  top: 14px;
  left: 18px;
  font-size: 21px;
  color: #727dca;
}

.Topbar .box2 .topbar-right .user-avatar {
  height: 52px;
  width: 52px;
  margin-left: 10px;
}

.Topbar .box1 .bars-btn {
  border: none;
  outline: none !important;
  font-size: 1.5rem;
  background-color: transparent;
}

.Topbar.hero .box1 .bars-btn {
  color: var(--black);
}

.Topbar.angel .box1 .bars-btn {
  color: var(--primaryBlueColor);
}

.user-avatar {
  position: relative;
  display: inline-block;
}

.user-avatar:hover {
  cursor: pointer;
}

.user_Dropdown {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 200px;
  z-index: 3;
}

.user_Dropdown .logout_btn {
  text-decoration: none;
  color: #000000;
  background-color: var(--darkGray);
  border-radius: 5px;
}
/* hero campaigns  modal */

.back-button:hover {
  cursor: pointer;
}

.back-button p {
  font-size: 24px;
  font-weight: 600;
  color: var(--dashboardActive);
}

@media (max-width: 991px) {
  .mobile-title {
    display: block;
    margin-top: 0.6rem;
    margin-left: 5px;
    font-size: 18px;
    font-weight: 600;
  }

  .Topbar .box2 .dashboard-mode {
    display: none;
  }

  .Topbar .box2 .topbar-right .user-avatar,
  .Topbar .box2 .topbar-right .notifications {
    height: 42px;
    width: 42px;
  }

  .Topbar .box2 .topbar-right .notification-count {
    top: -10px;
    left: 45px;
  }

  .Topbar .box2 .topbar-right .notifications .bell-icon {
    top: 8px;
    left: 13px;
  }

  .user_Dropdown {
    top: 10px;
    left: -120px;
  }

  .user_Dropdown .logout_btn {
    text-decoration: none;
    color: #000000;
    border-radius: 5px;
  }
}
