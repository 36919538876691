.campaign-details {
  width: 200px;
  min-height: 120px;
  padding: 10px 5px;
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  /* z-index: 99; */
}

.campaign-details:hover {
  cursor: pointer;
}

.campaign-details .about-campaign p {
  color: var(--darkGray);
  text-transform: capitalize;
}

.campaign-details .about-campaign p span {
  font-weight: 600;
  color: var(--black);
}

.campaign-details .description {
  height: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.campaign-details .view-campaign {
  font-weight: 600;
  text-align: center;
}
