.ChatMessage {
	width: fit-content;
	max-width: 500px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	padding: 12px;
	background-color: #fff;
	border-radius: 6px;
}

.ChatMessage.left {
	align-self: flex-start;
}

.ChatMessage.right {
	color: #fff;
	align-self: flex-end;
}

.ChatMessage.right.hero {
	background-color: var(--primaryYellowColor);
}

.ChatMessage.right.angel {
	background-color: var(--primaryBlueColor);
}

.ChatMessage .message {
	font-size: 14px;
	word-break: break-word;
}

.ChatMessage .time {
	margin-top: 8px;
	width: fit-content;
	font-size: 12px;
	margin-left: auto;
}

@media all and (max-width: 1000px) {
	.ChatMessage {
		max-width: 400px;
	}
}

@media all and (max-width: 600px) {
	.ChatMessage {
		max-width: 300px;
	}
}
