.BgShape {
    position: relative;
}

.BgShape .img-wrapper {
    position: absolute;
    width: 120px;
    height: 1000px;
    z-index: -999;
}

.BgShape.left .img-wrapper {
    width: 250px;
}

.BgShape .img-wrapper .img {
    width: 100%;
    height: 100%;
}

.BgShape.left .img-wrapper {
    left: 0%;
}

.BgShape.right .img-wrapper {
    right: 0%;
}

.BgShape.left .img-wrapper .img {
    background: url("../../../assets/images/VectorLeftSmall.png") center/cover
        no-repeat;
}

.BgShape.right .img-wrapper .img {
    background: url("../../../assets/images/VectorRightSmall.png") center/cover
        no-repeat;
}

@media all and (min-width: 768px) {
    .BgShape .img-wrapper {
        width: 405px;
        height: 800px;
    }

    .BgShape.left .img-wrapper {
        width: 405px;
        height: 850px;
    }

    .BgShape.left .img-wrapper .img {
        background: url("../../../assets/images/VectorLeft.png") center/cover
            no-repeat;
    }

    .BgShape.right .img-wrapper .img {
        background: url("../../../assets/images/VectorRight.png") center/cover
            no-repeat;
    }
}

@media all and (min-width: 992px) {
    .BgShape .img-wrapper {
        width: 615px;
        height: 1200px;
    }

    .BgShape.left .img-wrapper {
        width: 620px;
        height: 1275px;
    }
}
