#notfound .bg-img {
	width: 100vw;
	height: 100vh;
}

#notfound .bg-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#notfound .content {
	position: absolute;
	top: 35%;
	left: 50%;
	width: 100vw;
	transform: translate(-50%, -40%);
	z-index: 999;
	text-align: center;
	color: #f2f2f2;
}

#notfound .content h1 {
	font-size: 40px;
    margin-bottom: 50px;
	text-shadow: 0px 2px 4px rgba(191, 191, 191, 1);
}

#notfound .content h2 {
	font-size: 100px;
    margin-bottom: 50px;
	text-transform: uppercase;
	text-shadow: 0px 2px 4px rgba(191, 191, 191, 1);
}

#notfound .content h3 {
	font-size: 65px;
	text-shadow: 0px 2px 4px rgba(191, 191, 191, 1);
}

#notfound .content h3.first-child {
	margin-top: 1rem;
}

#notfound .content .wrap-button {
    width: 300px;
	margin: auto;
	padding-top: 20px;
}

#notfound .content .wrap-button .TribePrimaryBtn {
    margin-top: 40px;
	height: 50px;
    font-size: 1.5rem;
}
