.PrimaryBtn {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-decoration: none !important;
  text-align: center;
  border-radius: 20px;
  outline: none !important;
  border: none;
  color: #fff;
  font-weight: 600;
  transition: all 300ms ease;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.PrimaryBtn.sm {
  padding: 10px 0px;
  width: 100px;
  font-size: 14px;
}

.PrimaryBtn.md {
  padding: 8px 0px;
  width: 175px;
  font-size: 14px;
}

.PrimaryBtn.lg {
  padding: 10px 0px;
  width: 225px;
}

.PrimaryBtn.cover {
  width: 100%;
  padding: 8px 0px;
}

.PrimaryBtn.yellow {
  background-color: var(--primaryYellowColor);
}

.PrimaryBtn.blue {
  background-color: var(--primaryBlueColor);
}

.PrimaryBtn.LinearBlue {
  background: linear-gradient(180deg, #0085ff 0%, #0067ff 100%);
  box-shadow: 0px 4px 4px rgba(0, 103, 255, 0.14),
    0px 8px 8px rgba(0, 103, 255, 0.08);
  border-radius: 38px;
  color: #fff;
}

.PrimaryBtn.yellow:hover {
  color: #fff;
}

.PrimaryBtn.transparent {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}

.PrimaryBtn.transparentYellow {
  border: 1px solid #ec972f;
  color: #ec972f;
  background-color: transparent;
}

.PrimaryBtn.transparentBlue {
  text-align: center;
  border: 1px solid var(--primaryBlueColor);
  color: var(--primaryBlueColor);
  background-color: transparent;
}

.PrimaryBtn.tranparentLinearBlue {
  border: 1px solid #0067ff;
  color: #0067ff;
  background-color: transparent;
}

.PrimaryBtn.disabled {
  text-align: center;
  border: 1px solid #6c757d;
  color: #fff;
  background-color: #6c757d;
}

.PrimaryBtn .icon {
  font-size: 16px;
  margin-left: 8px;
}

@media all and (max-width: 375px) {
  .PrimaryBtn.md {
    padding: 8px 0px;
    width: 140px;
    font-size: 14px;
  }

  .PrimaryBtn.cover {
    font-size: 14px;
  }
}
