.previewUpdates {
  margin: 0 10px 0 10px;
}

.previewUpdates .updates-input p {
  font-size: 14px;
  color: #061024;
}

.previewUpdates .updates-input .updates-editor {
  margin-bottom: 10px;
}

.previewUpdates .updates-list {
  margin-top: 2em;
}

.previewUpdates .updates-list .update-item {
  padding-top: 5px;
  border-bottom: 1px solid #D9E0EC;
  margin-bottom: 5px;
  
}

.previewUpdates .updates-list .update-item .item-content {
  display: flex;
  flex-direction: row;
}

.previewUpdates .updates-list .update-item .item-content .checkmark-icon {
  height: 32px;
  width: 32px;
  margin-right: 10px;
}
.previewUpdates .updates-list .update-item .item-content .update-text p {
  font-size: 14px;
  font-weight: 100;
  line-height: 21px;
}

.item-info {
  display: flex;
  flex-direction: row;
  margin: 10px 0 10px 2.4em;
}

.item-info div span {
  color: gray;
  font-size: 14px;
  font-weight: 100;
}

.item-info .item-user {
  margin-right: 2rem;
}

.item-user .update_user, .item-date .update_time{
  margin-left: 5px;
  text-transform: capitalize;
  font-size: 14px;
  color: var(--darkGray);
}

@media all and (max-width: 472px) {
  .item-info div span {
    font-size: 12px;
  }

  .item-info {
    margin-left: 1em;
  }

  .item-info .item-user {
    margin-right: 10px;
  }
}
