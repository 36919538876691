.marker-filter {
  height: 50px;
  min-width: 750px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  padding-top: 12px;
}
.marker-filter p {
  font-family: IBMPlexSansR;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marker-filter p span {
  margin-right: 5px;
}

.marker-filter input:hover {
  cursor: pointer;
}

.filter-mobile {
  width: 320px;
}

.filter-mobile p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  font-family: IBMPlexSansR;
}

.dropdown-toggle {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fff !important;
  color: #000 !important; */
}

.dropdown-menu {
  inset: -6px auto auto 0px !important;
  border: none !important;
}

@media all and (max-width: 991px) {
  .marker-filter {
    flex-direction: column;
  }
}

@media all and (max-width: 400px) {
  .filter-mobile {
    width: 280px;
  }

  .dropdown-toggle {
    width: 280px;
  }
}
