.heroResponsible-section {
  margin-top: 1.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroResponsible-section .hero-section-title {
  font-size: 16px;
  text-transform: uppercase;
}

/* Hero Detail Component */

.hero-details {
  margin-top: 10px;
  width: 100%;
}

.hero-details .hero-info {
  background-color: #f4f9fd;
  border-radius: 16px;
  float: none;
  margin: 0 auto;
}

.hero-details .hero-info .hero-avatar {
  height: 68px;
  width: 68px;
}

.hero-details .hero-info .hero-name {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.hero-details .hero-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  margin-top: 10px;
}

.hero-details .hero-profile .hero-counts {
  margin-top: 10px;
  color: var(--dashboardActive);
  line-height: 10px;
}

.hero-counts p:first-of-type {
  font-size: 24px;
}

.hero-counts p:last-of-type {
  font-size: 14px;
  font-weight: 100;
}

.hero-details .hero-location {
  font-size: 14px;
  margin-top: 10px;
}

.hero-details .hero-location span {
  color: var(--dashboardActive);
  font-weight: 100;
}

.hero-details .responsibleHero-btns {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.responsibleHero-btns .location-btn {
  margin-left: 8px;
  height: 45px;
  width: 45px;
  border: none;
  background: linear-gradient(180deg, #0085ff 0%, #0067ff 100%);
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
}

.responsibleHero-btns .location-btn .location-btn-icon {
  margin-top: 50%;
  transform: translate(0%, -50%);
}

/* Hero Detail Component end */

/* Hero Causes */

.hero-causes-section {
  margin-top: 10px;
  height: 720px;
}

.hero-causes-section .hero-causes-heading {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.hero-causes-wrap {
  overflow-y: scroll;
  height: 670px;
}

.hero-causes-wrap::-webkit-scrollbar {
  display: none;
}

.hero-causes-section .hero-causes-wrap .hero-causes-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-causes-list .hero-cause-item {
  height: 118px;
  width: 250px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  box-shadow: 2px 7px 5px -2px rgba(233, 231, 231, 0.75);
  margin-top: 10px;
  margin-bottom: 10px;
}

.hero-cause-item .cause-image {
  flex: 40;
}

.hero-cause-item .cause-image img {
  width: 100%;
  height: 100%;
}

.hero-cause-item .cause-details {
  flex: 60;
  display: flex;
  flex-direction: column;
  line-height: 12px;
  margin-left: 10px;
}

.cause-details .hero-cause-category {
  margin-top: 5px;
  font-size: 12px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #eaf3ff;
  width: 50%;
}

.cause-details .hero-cause-title {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cause-details .hero-cause-goal {
  font-size: 16px;
  color: #9fa2b4;
}

.cause-details .hero-cause-goal span {
  font-weight: 500;
}

.cause-details .view-more-btn {
  background: none;
  border: none;
  font-size: 14px;
  color: var(--dashboardActive);
  text-align: start;
  text-decoration: none;
}
