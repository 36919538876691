.PrimaryAuthInput {
    display: block;
    color: #222;
    width: 100%;
    border: none;
    outline: none;
    padding: 8px 14px;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid #979797;
}

.PrimaryAuthInput.rounded {
    border-radius: 10px !important;
}

.PrimaryAuthInput.topBordersRounded {
    border-bottom: none;
    border-radius: 10px 10px 0px 0px !important;
}

.PrimaryAuthInput.bottomBordersRounded {
    border-top: 1px solid #979797;
    border-radius: 0px 0px 10px 10px !important;
}
