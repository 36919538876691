.profile_card{
    height: 292px;
    background-color: #fff;
    border-radius: 20px;
    padding:20px;
    width: 100%;
    
    
}

.profile_card .card_top{
    display: flex;
    margin-bottom: 10px;
}

.card_top .user-avatar{
    height: 100px;
    width: 100px;
}

.card_top .user_name{
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    align-items: center;
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    font-weight: 500;
}

.profile_card .card_main{
    padding-top: 1rem;
    font-size: 18px;
    font-weight: 500;
    color: var(--darkGray);
}

.card_main p{
    margin-bottom: 0px;
}

.card_main .joined-date span{
    color: var(--black);
}

.card_main .location span{
    color: var(--dashboardActive);
}

.profile_card .user-buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}