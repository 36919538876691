.ChatTimeline {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ChatTimeline .head,
.ChatTimeline .footer {
	margin: 0px 2rem;
}

.ChatTimeline .body {
	width: 99%;
	height: 100%;
	padding: 12px;
	display: flex;
	flex-direction: column;
	background-image: url("../../../../assets//images/chat-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	overflow-y: scroll;
}

.ChatTimeline .body .ChatMessage:not(:last-child) {
	margin-bottom: 12px;
}

.ChatTimeline .footer {
	display: flex;
	padding: 20px 0px;
}

.ChatTimeline .footer .wrap-input {
	flex: 1;
}

.ChatTimeline .footer .wrap-btn {
	margin-left: 1rem;
}

@media all and (max-width: 992px) {
	.ChatTimeline .body {
		width: 100%;
		height: 80vh;
	}
}

@media all and (max-width: 500px) {
	.ChatTimeline .footer {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

    .ChatTimeline .footer .wrap-input {
        width: 100%;
    }

	.ChatTimeline .footer .wrap-btn {
		margin: 0;
		margin-top: 1rem;
	}
}
