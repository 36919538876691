.EnterpriseStepOne {
  width: 100%;
}

.EnterpriseStepOne .input-wrapper:not(:first-child),
.modal-main-body .input-wrapper {
  margin-top: 1rem;
}

.EnterpriseStepOne .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

/* file upload */
.enterprise-image-upload-wrap {
  border: 2px dashed #c0c7d1;
}

.enterprise-image-upload-wrap:hover {
  cursor: pointer;
}

.image-placeholder {
  height: 50px;
  width: 50px;
  margin: 10px auto;
}

.story-cover .enterprise-image-upload-wrap input {
  opacity: 0;
  width: 550px;
}

.story-cover .enterprise-image-upload-wrap input:hover {
  cursor: pointer;
}

.story-cover .enterprise-image-upload-wrap .drag-text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
}

.image-type {
  text-align: center;
  font-size: 12px;
  color: #c0c7d1;
}

/* ProductStage Modal */
.productStageModal .modal-top {
  margin-top: 10px;
}

.productStageModal .modal-top .modal-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.productStageModal .modal-top .close-btn {
  border: none;
  background: none;
  float: right;
  position: absolute;
  top: 10px;
  right: 1.25rem;
  color: var(--darkGray);
  font-size: 20px;
}

.productStageModal .modal-main-body {
  margin: 10px 1rem;
}

.fileSize-note {
  font-size: 12px;
  color: var(--darkGray);
  text-align: center;
  margin-top: 0.45rem;
}

.fileSize-note span {
  font-weight: bold;
  font-style: normal;
}

@media all and (max-width: 991px) {
  .enterprise-image-upload-wrap {
    display: flex;
    justify-content: center;
  }

  .input-image {
    display: none;
  }

  .enterprise-image-upload-wrap .image-placeholder {
    margin: 10px 30px 0 10px;
    padding: 10px 0;
  }

  .image-input-field {
    display: none;
  }

  .image-text {
    margin-top: 10px;
    padding: 10px 0;
  }
}
