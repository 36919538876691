.CauseStepOne {
  width: 100%;
}

.CauseStepOne .input-wrapper:not(:first-child) {
  margin-top: 1rem;
}

.CauseStepOne .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.textarea-count {
  color: var(--darkGray);
  position: relative;
  left: 84%;
  margin-top: 2px;
  font-size: 12px;
}

@media all and (max-width: 991px) {
  .textarea-count {
    display: none;
  }
}
