#Footer {
  width: 100vw;
  color: #fff;
  background: #000;
  /* margin-top: 20px; */
  padding-top: 30px;
  margin: auto;
}

#Footer .Footer-body .content {
  margin-top: 20px;
}

#Footer .Footer-body .content .links-list .link {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

#Footer .Footer-body .content .language {
  margin: 20px 0px;
}

#Footer .Footer-bottom {
  display: flex;
  flex-direction: column-reverse;
}

#Footer .Footer-bottom .text {
  font-size: 0.875rem;
  font-weight: 400;
}

#Footer .Footer-bottom .wrap-media-icons {
  display: flex;
  margin-bottom: 8px;
}

#Footer .Footer-bottom .wrap-media-icons .media-icon {
  text-decoration: none !important;
  color: #fff;
  font-size: 1.5rem;
}

#Footer .Footer-bottom .wrap-media-icons .media-icon:not(:first-child) {
  margin-left: 1rem;
}

@media all and (min-width: 576px) {
  #Footer .Footer-body {
    display: flex;
    justify-content: space-between;
  }

  #Footer .Footer-body .content {
    margin-top: 0px;
    display: flex;
  }

  #Footer .Footer-body .content > :not(:first-child) {
    margin-left: 30px;
  }

  #Footer .Footer-body .content .language {
    margin: 0px;
    margin-top: -5px;
    margin-left: 30px;
  }

  #Footer .Footer-bottom {
    margin-top: 60px;
    margin-bottom: 60px;
    flex-direction: row;
    justify-content: space-between;
  }

  #Footer .Footer-bottom .wrap-media-icons {
    margin-right: 1rem;
  }

  #Footer .Footer-bottom .wrap-media-icons .media-icon {
    font-size: 1.25rem;
  }
}
