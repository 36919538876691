.SignupModal{
    margin: 10px 10px;
}

.SignupModal .screenOne-text {
    text-align: center;
    color: #000;
}

.SignupModal .wrap-extraBtns {
    display: flex;
    justify-content: center;
}

.SignupModal .wrap-extraBtns .skipScreenBtn {
    background: transparent;
    border: none !important;
    outline: none !important;
    text-decoration: underline;
    color: #929292;
    font-size: 0.875rem;
    transition: all 200ms ease;
}

.SignupModal .wrap-extraBtns .skipScreenBtn:hover {
    color: #000;
}

.SignupModal .text-screenFour {
    color: #000;
    font-weight: bold;
    font-size: 0.875rem;
}

.SignupModal .center-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignupModal .center-img .wrap-dummyImg {
    width: 120px;
    height: 120px;
}

.SignupModal .center-img .wrap-dummyImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SignupModal .title-screenFive {
    text-align: center;
    font-weight: bold;
    margin: 8px 0px 20px;
}
