.CheckBox {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.CheckBox .customCheckBox.sm {
	min-width: 15px;
	min-height: 15px;
}

.CheckBox .customCheckBox.md {
	min-width: 20px;
	min-height: 20px;
}

.CheckBox label {
	color: #fff;
	margin-left: 6px;
	margin-top: -2px;
	font-size: 14px;
}

.CheckBox label.white {
	color: #fff;
}

.CheckBox label.dark {
	color: #000;
}
