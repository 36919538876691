@font-face {
  font-family: IBMPlexSansRegular;
  src: url("../../../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");
}

.DonationStep {
  width: 100%;
}

.DonationStep .input-wrapper:not(:first-child) {
  margin-top: 1rem;
}

.anonymous-checkbox {
  font-family: IBMPlexSansR;
  font-size: 12px;
  align-items: center;
}

.anonymous-checkbox label {
  margin-top: 10px;
}

.DonationStep .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.DonationStep .btn-wrapper span {
  margin-left: 1rem;
}

.payment_methods .payment_btns {
  display: flex;
  flex-direction: column;
}

.payment_btns .radiobtn-wrapper {
  margin-bottom: 0.5rem;
  display: flex;
}

.radiobtn-wrapper label {
  font-size: 14px;
  font-weight: 500;
  margin-left: 0.645rem;
}

.radiobtn-wrapper .credit-card-label {
  display: inline-flex;
  justify-content: space-between;
  width: 90%;
}

.radiobtn-wrapper .credit-card-label p {
  margin-bottom: 0;
}

.payment_btns .payment-type {
  width: 20px;
  height: 20px;
}

.email-exist-message {
  font-size: 14px;
  font-weight: 500;
}

.email-exist-message .login-icon {
  color: #34db8b;
}

.email-exist-message span {
  font-weight: 700;
  color: #27d983;
}

@media all and (max-width: 991px) {
  .payment_methods .payment-label {
    font-size: 14px;
  }
}

/* stripe btn style override */
.donate_btn .StripeCheckout {
  background: linear-gradient(180deg, #0085ff 0%, #0067ff 100%) !important;
  box-shadow: 0px 4px 4px rgba(0, 103, 255, 0.14),
    0px 8px 8px rgba(0, 103, 255, 0.08) !important;
  border-radius: 38px !important;
  font-weight: 600;
  color: #fff !important;
  padding: 10px 0px;
  width: 175px;
  height: 40px;
}

.donate_btn .StripeCheckout span {
  background-image: none !important;
  text-transform: uppercase;
  text-decoration: none !important;
  text-align: center;
  outline: none !important;
  border: none;
  transition: all 300ms ease;
  font-size: 14px;
  line-height: 0px;
  font-family: IBMPlexSansRegular !important;
  font-weight: 600;
  box-shadow: none !important;
}
