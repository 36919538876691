.story-wrap {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 200;
}

.story-wrap .story-head {
  display: flex;
  justify-content: space-between;
}

.story-wrap .story-head .story-title {
  font-size: 20px;
  font-weight: 400;
}

.story-head .edit-icon {
  color: #727dca;
  border: none;
  background: none;
}
