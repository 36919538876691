.radio-wrap {
  border: 1px solid var(--darkGray);
  height: 44px;
  margin-top: 0.625rem;
  border-radius: 5px;
}

.radio-wrap .radio-heading {
  margin-top: 5px;
}

.radio-wrap .radiobtns-inputs {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  margin-left: 15px;
}

.radio-wrap .radiobtns-inputs .input-item {
  margin-right: 40px;
  margin-top: 7px;
  align-items: center;
  text-align: center;
}

.radio-wrap .radiobtns-inputs .input-item input {
  width: 22px;
  height: 22px;
  -webkit-appearance: none;
  border: 0.125rem solid #0067ff;
  border-radius: 50%;
  outline: none;
}

.radio-wrap .radiobtns-inputs .input-item input::before {
  content: "";
  display: block;
  width: 50%;
  height: 50%;
  margin: 24% auto;
  border-radius: 50%;
}

.radio-wrap .radiobtns-inputs .input-item input:checked::before {
  background-color: #0067ff;
}

.radio-wrap .radiobtns-inputs .radio-label {
  display: none;
}

@media all and (max-width: 991px) {
  .radio-wrap {
    height: 100px;
  }

  .radio-wrap .radio-heading {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  .radio-wrap .radiobtns-inputs .input-item {
    margin-right: 30px;
    align-items: center;
    justify-content: center;
  }

  .radio-wrap .radiobtns-inputs .radio-label {
    display: block;
    font-size: 10px;
    font-weight: bold;
  }
}
