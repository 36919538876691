.Backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100vw;
}

.Backdrop .Backdrop-Img {
    width: 100vw;
    height: 250px;
}

.Backdrop .Backdrop-Img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Backdrop .Backdrop-Title {
    position: absolute;
    bottom: 10%;
    left: 10%;
}

@media all and (min-width: 700px) {
    .Backdrop .Backdrop-Title {
        left: 15%;
    }
}

@media all and (min-width: 768px) {
    .Backdrop .Backdrop-Img {
        height: 330px;
    }
}

@media all and (min-width: 992px) {
    .Backdrop .Backdrop-Img {
        height: 400px;
    }
}
