.HeroDashboard .wrap-radar {
	height: 500px;
}

.HeroDashboard .wrap-radar canvas {
	height: 100% !important;
}

.HeroDashboard .box {
	margin-top: 1rem;
	width: 100%;
	height: 320px;
	background-color: white;
	border: 'thin';
	margin-top: 5rem;
}

.HeroDashboard .box:last-child {
	position: relative;
	margin-top: 1rem;
	width: 100%;
	height: 300px;
	background-color: green;

}

.HeroDashboard .box .map {
	position: static;
}
