.UpdatePassword {
  width: 100vw;
  min-height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UpdatePassword .UpdatePassword-Section {
  flex: 1;
}

.UpdatePassword .UpdatePassword-Body .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UpdatePassword .UpdatePassword-Body .header .header-times {
  font-size: 2.5rem;
  color: #eb982f;
}
