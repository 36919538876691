.ChatUserCard {
	cursor: pointer;
	display: flex;
	width: 100%;
	padding: 4px 8px;
	margin-bottom: 0.8rem;
	transition: all 300ms ease;
}

.ChatUserCard:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.ChatUserCard .wrap-img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.ChatUserCard .wrap-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
	border-radius: 50%;
}

.ChatUserCard .wrap-content {
	flex: 1;
	margin-left: 1rem;
	border-bottom: 1px solid #ccc;
}

.ChatUserCard .wrap-content .box {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ChatUserCard .wrap-content .box .name {
	font-weight: 500;
	font-size: 1.125rem;
}

.ChatUserCard .wrap-content .box .last-online {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.4);
}

.ChatUserCard .wrap-content .box2 {
	margin-top: 4px;
	margin-bottom: 8px;
}

.ChatUserCard .wrap-content .box2 .message {
	font-size: 16px;
}

@media all and (max-width: 1500px) {
	.ChatUserCard {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #ccc;
	}

	.ChatUserCard .wrap-content {
		border: none;
		margin: 0;
		margin-top: 4px;
	}

	.ChatUserCard .wrap-content .box1 {
		flex-direction: column;
	}

	.ChatUserCard .wrap-content .box2 {
		display: none;
	}
}

@media all and (max-width: 1199px) {
	.ChatUserCard {
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		border: none;
	}

	.ChatUserCard .wrap-content {
		flex: 1;
		margin-left: 1rem;
		border-bottom: 1px solid #ccc;
	}

	.ChatUserCard .wrap-content .box1 {
		flex-direction: row;
	}

	.ChatUserCard .wrap-content .box2 {
		display: block;
	}
}
