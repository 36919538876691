.NavbarWithBackdrop {
    position: relative;
    height: 250px;
}

@media all and (min-width: 768px) {
    .NavbarWithBackdrop {
        height: 330px;
    }
}

@media all and (min-width: 992px) {
    .NavbarWithBackdrop {
        height: 400px;
    }
}
