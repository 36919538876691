.hero_profile_container {
  margin: auto;
}

.user_campaigns .campaigns_top {
  display: flex;
  justify-content: space-between;
  /* padding-left: 60px;
  padding-right: 60px; */
}

.user_campaigns .campaigns_top p {
  font-size: 32px;
  font-weight: 600;
  color: var(--black);
}

.user_campaigns .campaigns_top button {
  font-size: 24px;
  font-weight: 500;
  color: var(--dashboardActive);
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.campaigns_top button:hover {
  cursor: pointer;
  color: var(--dashboardActive);
}

.user_campaigns .campaigns-container .card-wrapper {
  margin-bottom: 2.5rem;
}

.mobile-only .campaigns-container {
  display: none;
}

.mobile-only .campaigns-container.active {
  display: block;
}

.mobile-only .top-buttons {
  border-bottom: 2px solid #e2e8f3;
  margin-bottom: 1rem;
}

.mobile-only .top-buttons p {
  color: var(--black);
  text-transform: uppercase;
  margin-bottom: 0px;
  padding-bottom: 0.5rem;
}

.mobile-only .top-buttons p.active {
  color: var(--dashboardActive);
  border-bottom: 2px solid var(--dashboardActive);
}

/* hero causes/enterprises component style */

.heroCampaigns-backBtn {
  font-weight: 600;
  font-size: 24px;
  color: var(--dashboardActive);
}

.heroCampaigns-backBtn:hover {
  cursor: pointer;
}

@media all and (max-width: 991px) {
  .user_campaigns .campaigns_top {
    display: none;
  }
}
