.SearchBar {
    position: absolute;
    margin: 0px auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 0.5rem 0rem;
    z-index: 200;
    background-color: #fff;
}

.SearchBar .Wrap-Bar {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.SearchBar .Wrap-Dropdown > :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.SearchBar .Wrap-Dropdown .option {
    font-size: 1rem;
    padding: 0.3em 0.5em;
}

.SearchBar.desktop {
    top: -40%;
    left: 0;
    width: 370px;
}

.SearchBar.mobile {
    top: -30%;
    left: -330%;
    width: 200px;
}

.SearchBar.false {
    display: none !important;
}

.SearchBar.true {
    display: block;
}

.SearchBar .InputBox {
    flex: 1;
}

.SearchBar .InputBox .InputField {
    display: block;
    border: none !important;
    outline: none !important;
    width: 100%;
    padding-left: 1rem;
}

.SearchBar .SearchIconBox {
    padding-left: 1rem;
    cursor: pointer;
}

.SearchBar .TimesIconBox {
    padding-right: 1rem;
    cursor: pointer;
}

.SearchBar .TimesIconBox span {
    font-size: 18px;
}

@media all and (min-width: 768px) {
    .SearchBar.mobile {
        top: -30%;
        left: -950%;
        width: 500px;
    }
}
