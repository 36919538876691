.About .about-tribe {
    margin: 80px 0px;
}

.About .about-tribe .wrap-content .text-content {
    margin-top: 1rem;
}

.About .about-tribe .wrap-content .text-content p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}

.About .about-tribe .wrap-content .text-content .titles {
    margin-top: 1rem;
}

.About .about-tribe .illustraions-box {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
}

.About .about-tribe .illustraions-box .heroImg1 {
    width: 250px;
    height: 320px;
}

.About .about-tribe .illustraions-box .heroImg2 {
    position: relative;
    transform: translateX(-60%);
    width: 150px;
    height: 250px;
    z-index: -1;
}

.About .about-tribe .illustraions-box .wrap-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.About .wrapper-projects {
    margin-top: 80px;
}

.About .wrapper-footer {
    margin-top: 120px;
}

@media all and (min-width: 768px) {
    .About .about-tribe .wrap-content .text-content p {
        font-size: 18px;
    }
}

@media all and (min-width: 1200px) {
    .About .about-tribe .illustraions-box .heroImg1 {
        width: 400px;
        height: 470px;
    }

    .About .about-tribe .illustraions-box .heroImg2 {
        width: 300px;
        height: 350px;
    }
}
