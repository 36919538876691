.world-impact {
  background-color: #ffffff;
  border-radius: 20px;
  margin-right: 10px;
  min-height: 292px;
  overflow: hidden;
}

.world-impact .impact-heading-top {
  display: none;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 0 1.235rem;
  padding-top: 1.245rem;
}

.worldImpact-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.worldImpact-wrap .impact-left {
  margin: 0.345rem 0 0 0.635rem;
  flex: 65%;
}

.impact-left .name-values {
  margin-top: 1.345rem;
}

.worldImpact-wrap.impact-right {
  flex: 35%;
}

.world-impact .impact-heading {
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 0 1.235rem;
  padding-top: 1.245rem;
}

@media all and (max-width: 991px) {
  .worldImpact-wrap {
    flex-direction: column-reverse;
  }

  .world-impact .impact-heading-top {
    display: block;
  }

  .impact-heading {
    text-align: center;
  }
  .worldImpact-wrap .impact-left {
    margin: 0.345rem 0 0 0.635rem;
    flex: 100%;
  }

  .impact-left .name-values {
    margin-top: 0.445rem;
  }

  .impact-left .impact-heading {
    display: none;
  }

  .worldImpact-wrap .impact-right {
    flex: 100%;
  }
}

@media all and (min-width: 991px) {
  .world-impact {
    height: 292px;
  }
}

@media all and (min-width: 1900px) {
  .worldImpact-wrap .impact-left {
    flex: 60%;
  }

  .worldImpact-wrap .impact-right {
    flex: 40%;
  }
}
