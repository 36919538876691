/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
	width: 100%;
}

.responsiveTable td .tdBefore {
	display: none;
}

.responsiveTable.hero thead {
	background-color: var(--primaryYellowColor);
}

.responsiveTable.angel thead {
	background-color: var(--primaryBlueColor);
}

.responsiveTable thead tr th {
	text-align: center;
}

.responsiveTable thead tr th:first-child {
	border-radius: 12px 0px 0px 0px;
}

.responsiveTable thead tr th:last-child {
	border-radius: 0px 12px 0px 0px;
}

.responsiveTable thead th {
	padding-left: 8px;
	font-size: 1.125rem;
	font-weight: 500;
	padding: 8px 0px;
}

.responsiveTable.hero tbody tr td {
	padding: 6px 0px 6px 8px;
	border: 1px solid var(--primaryYellowColor);
}

.responsiveTable.angel tbody tr td {
	padding: 6px 0px 6px 8px;
	border: 1px solid var(--primaryBlueColor);
}

.responsiveTable .actions-group > *:not(:first-child) {
	margin-left: 14px;
}

@media screen and (max-width: 900px) {
	.responsiveTable table,
	.responsiveTable thead,
	.responsiveTable tbody,
	.responsiveTable th,
	.responsiveTable td,
	.responsiveTable tr {
		display: block;
	}

	.responsiveTable thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
		border-bottom: 2px solid #333;
	}

	.responsiveTable tbody tr {
		border: 1px solid #000;
		padding: 0.25em;
	}

	.responsiveTable.hero tbody tr {
		border: 1px solid var(--primaryYellowColor);
		padding: 0.25em;
	}

	.responsiveTable.angel tbody tr {
		border: 1px solid var(--primaryBlueColor);
		padding: 0.25em;
	}

	.responsiveTable td.pivoted {
		/* Behave like a "row" */
		border: none !important;
		position: relative;
		padding-left: calc(50% + 10px) !important;
		text-align: left !important;
		white-space: pre-wrap;
		overflow-wrap: break-word;
	}

	.responsiveTable td .tdBefore {
		/* Now like a table header */
		position: absolute;
		display: block;

		/* Top/left values mimic padding */
		left: 1rem;
		width: calc(50% - 20px);
		white-space: pre-wrap;
		overflow-wrap: break-word;
		text-align: left !important;
		font-weight: 600;
	}

	.responsiveTable .actions-group {
		display: flex;
		flex-direction: column;
	}

	.responsiveTable .actions-group > *:not(:first-child) {
		margin: 8px 0px;
	}
}
