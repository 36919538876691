.ProjectCard {
	font-family: IBMPlexSansR;
	cursor: pointer;
	width: 370px;
	background-color: #fafafa;
	border-radius: 10px;
	margin: auto;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	transition: all 300ms ease-in-out;
}

.ProjectCard .top-img {
	border-radius: 8px;
	width: 100%;
	height: 165px;
}

.ProjectCard .top-img img {
	border-radius: 8px 8px 0px 0px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.wrapper-title{
	display: flex;

}

.wrapper-title .category-icon{
	padding-top: 3px;
	text-align: center;
	color: #0067FF;
	background: #F2F5FB;
	border-radius: 50%;
	height: 48px;
	width: 48px;
	align-items: center;
	font-size: 25px;
	font-weight: 100;
	margin-right: 10px;
}


.wrapper-title .card_title{

	font-size: 24px;
	font-weight: 600;
	text-transform: capitalize;
}

.ProjectCard .content-box {
	padding: 20px 14px 30px 14px;
}

.ProjectCard .content-box .text {
	color : #080C22;
}

.ProjectCard .content-box .location-details{
	font-family: IBMPlexSansRegular;
	font-size: 14px;
	font-weight: 500;
	color: var(--darkGray);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ProjectCard .content-box .location-details span{
	color: #0067FF;
}

.ProjectCard .content-box .goal_and_achieved, .ProjectCard .content-box .charity_details, .ProjectCard .content-box .footer{
	display: flex;
	justify-content: space-between;
}

.ProjectCard .content-box .goal_and_achieved{
	color: #000000;
	font-weight: 600;
}

.ProjectCard .content-box .goal_and_achieved p{
	margin-bottom: 0px;
}


.ProjectCard .content-box .progress {
	height: 12px;
	margin: 8px 0px;
	background-color: #C0C7D1;
	border-radius: 20px;
}


.card-progressbar .content-box .progress .progress-bar  {
	background-color: #0067FF;
}

.progress.complete .progress-bar{
	background-color: #27D983;
}


.ProjectCard .content-box .charity_details{
	font-size: 10px;
	font-weight: 500;
	color: var(--darkGray);
}

.ProjectCard .content-box .footer{
	font-size: 14px;
	color: var(--darkGray);

	font-weight: 400;
}

.footer .charity-comments span{
	margin-left: .345rem;
	text-align: center;
}

.footer .charity-donations{
	font-size: 12px;
}

.footer .charity-donations .donates{
	margin-left: 10px;
	display: inline-flex;
	flex-direction: row-reverse;
}

.footer .charity-donations .donates .donates-avatars{
	height: 24px;
	width: 24px;
	display: inline-block;
	margin-left: -10px;
}




/* project card  */


@media all and (max-width: 991px) {
	.ProjectCard {
		max-width: 380px;
	}

	.ProjectCard .top-img {
		height: 185px;
	}
}

@media all and (max-width: 375px) {
	.ProjectCard {
		max-width: 280px;
	}	
}




@media all and (min-width: 1400px) {

	.ProjectCard .top-img {
		height: 200px;
	}
}

@media all and (min-width: 2200px) {

	.ProjectCard .top-img {
		height: 250px;
	}
}