@font-face {
  font-family: IBMPlexSansR;
  src: url("../../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}

.rewards{
  margin-top: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  margin-right: 10px;
}

.caret-icon{
  color: var(--darkGray);
}

.rewards-top{
  padding-top: 0.6rem;
}

.rewards-top .rewards-heading {
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 0 1.235rem; 
  padding-top: 1.245rem;
  display: inline;
}

.rewards-top .add-reward{
  color: var(--dashboardActive);
  font-size: 14px;
  font-weight: 500;
  display: inline;
  margin-left: 70%;
  background: none;
  border: none;
}

.rewards-wrap{
  margin: 10px 10px;
}

.rewards-wrap .rewards-table{
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.rewards-table tr {
  border-bottom: 1px solid #e2e8f3;
}

.rewards-table tr.table-headings {
  font-size: 16px;
  font-weight: 400;
  border: none;
}

.rewards-table tr td,
 .rewards-table tr th {
  padding: 8px;
  font-size: 16px;
  color: #080c22;
}

.rewards-table tr th {
  font-weight: 100;
}
.rewards-table tr th span {
  margin-left: 10px;
}

.table-data {
  margin-bottom: 10px;
}


.rewards-table .table-data td{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:nowrap;

}



.table-data .regular-columns{
  font-weight: 100;
  font-family: IBMPlexSansR;
  font-size: 16px;
}

.reward-edit-btn{
  border: 1px solid var(--dashboardActive);
  text-transform: uppercase;
  color:var(--dashboardActive);
  background: none;
  border-radius: 38px;
  padding: 5px 10px;
}

/* add reward modal */

.addReward-modal .modal-top {
  margin-top: 10px;
}

.addReward-modal .modal-top .modal-title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.addReward-modal .modal-top .close-btn {
  border: none;
  background: none;
  float: right;
  position: absolute;
  top: 10px;
  right: 1.25rem;
  color: var(--darkGray);
  font-size: 20px;
}

.addReward-modal .modal-main {
  margin: 10px 20px 10px 20px;
}


.addReward-modal  .modal-main .reward-input-wrap{
  margin-bottom: 10px;  
}


/* reward mobile only */

.rewards-mobileOnly{
    margin: 10px 20px;
}

.rewards-mobile{
  display: none;
}



@media all and (max-width: 991px) {

  .rewards-desktop{
    display: none;
  }

  .rewards-mobile{
    display: block;
    padding: 10px 2px;
  }

  .rewards-wrap{
    flex-direction: column-reverse;
  }
}
