.SettingCard {
	max-width: 500px;
	height: 220px;
	display: flex;
	border: 1px solid #ccc;
	border-radius: 1rem;
	transition: all 300ms ease;
}

.SettingCard.hero {
	border: 1px solid var(--primaryYellowColor);
}

.SettingCard.angel {
	border: 1px solid var(--primaryBlueColor);
}

.SettingCard > * {
	padding: 1.25rem 1.5rem;
	border-radius: 0px 1rem 1rem 0px;
	transition: all 300ms ease;
}

.SettingCard .wrap-img {
	align-self: center;
	width: 250px;
	height: 150px;
}

.SettingCard .wrap-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.SettingCard .content .text {
	margin-top: 0.875rem;
	font-weight: 500;
}

.SettingCard.hero .content .title,
.SettingCard.hero .content .link {
	color: var(--primaryYellowColor);
}

.SettingCard.angel .content .title,
.SettingCard.angel .content .link {
	color: var(--primaryBlueColor);
}

/* Hover effects */
.SettingCard.hero:hover .content {
	background-color: var(--primaryYellowColor);
}

.SettingCard.angel:hover .content {
	background-color: var(--primaryBlueColor);
}

.SettingCard.angel:hover .content .title,
.SettingCard.hero:hover .content .title {
	color: #fff !important;
}

.SettingCard.angel:hover .content .text,
.SettingCard.hero:hover .content .text {
	color: #fff !important;
}

.SettingCard.angel:hover .content .link,
.SettingCard.hero:hover .content .link {
	color: #fff !important;
}

@media all and (max-width: 1300px) {
	.SettingCard .wrap-img {
		padding: 1.25rem 0.5rem;
	}
}

@media all and (max-width: 450px) {
	.SettingCard {
		max-width: 350px;
		height: 220px;
	}
}

@media all and (max-width: 420px) {
	.SettingCard .wrap-img {
		display: none;
	}

	.SettingCard .content {
		border-radius: 1rem;
	}
}
