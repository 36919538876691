.HowItWorks .HowItWorks-Body {
	padding: 40px 0px;
}

.HowItWorks .HowItWorks-Body .Faq-Section .section-title {
	margin-bottom: 1rem;
}

.HowItWorks .HowItWorks-Body .Faq-Section:not(:first-child) .section-title {
	margin-top: 2rem;
}
