.user-marker {
  height: 60px;
  width: 60px;
}
.user-marker:hover {
  cursor: pointer;
}

.user-avatar-top {
  position: absolute;
  inset: auto auto 0px 0px;
  transform: translate(5px, 25px);
  height: 60px;
  width: 60px;
}

.user-details {
  min-width: 240px;
  min-height: 135px;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  /* z-index: 99; */
}

.user-details:hover {
  cursor: pointer;
}

.user-details .user-avatar {
  height: 80px;
  width: 80px;
}

.user-details .about-user .userInfo {
  margin-bottom: 0.45rem;
  color: var(--darkGray);
}

.user-details .about-user .userInfo span {
  color: var(--dashboardActive);
}

.user-details .about-user .user-name {
  font-weight: 600;
  text-transform: capitalize;
}

.user-details .view-profile {
  font-weight: 600;
}

/* marker circle */
.bottom-circle {
  position: relative;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-circle .outer-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fe9800;
  mix-blend-mode: lighten;
  opacity: 0.16;
  border-radius: 50%;
}

.bottom-circle .firstInner-circle {
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #fe9800;
  opacity: 0.4;
  border-radius: 50%;
}

.bottom-circle .secondInner-circle {
  position: absolute;
  width: 25%;
  height: 25%;
  background-color: #fe9800;

  border-radius: 50%;
}

.bottom-circle .thirdInner-circle {
  position: absolute;
  width: 8%;
  height: 8%;
  background-color: #fff;
  border-radius: 50%;
}

/* marker circle */

/* bootstrap popover  */

.popover {
  line-height: 1;
  border: none;
}

.popover-body {
  padding: 0px 0px;
}

@media all and (max-width: 991px) {
  .user-marker {
    height: 45px;
    width: 45px;
  }
  .user-avatar-top {
    position: absolute;
    inset: auto auto 0px 0px;
    transform: translate(5px, 25px);
    height: 45px;
    width: 45px;
  }
}
