.Navbar {
	position: relative;
}

.Navbar-wrapper {
	margin-top: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Navbar-wrapper .Wrap-menubar .Menubar {
	position: relative;
	list-style: none;
	display: flex;
	align-items: center;
}

.Navbar-wrapper .Wrap-menubar .Menubar-mobile,
.Navbar-wrapper .Wrap-menubar .Menubar-desktop {
	margin-top: 14px;
}

.Navbar-wrapper .Wrap-menubar .Menubar .Menubar-item {
	position: relative;
}

.Navbar-wrapper .Wrap-menubar .Menubar .Menubar-item .Menubar-link {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
}

.Navbar-wrapper.white .Wrap-menubar .Menubar .Menubar-item .Menubar-link,
.Navbar-wrapper.white .Menubar-item .searchbar {
	color: #fff;
}

.Navbar-wrapper.dark .Wrap-menubar .Menubar .Menubar-item .Menubar-link,
.Navbar-wrapper.dark .Menubar-item .searchbar {
	color: #333;
}

.Navbar-wrapper .Wrap-menubar .Menubar-desktop .Menubar-item .Menubar-link {
	font-size: 15px;
}

.Navbar-wrapper .Wrap-menubar .Menubar-desktop .Menubar-item {
	margin: 0px 10px;
}

.Navbar-wrapper .Wrap-menubar .Menubar-mobile .Menubar-item .Menubar-link {
	font-size: 14px;
}

.Navbar-wrapper .Wrap-menubar .Menubar-mobile .Menubar-item .searchbar,
.Navbar-wrapper .Wrap-menubar .Menubar-desktop .Menubar-item .searchbar {
	cursor: pointer;
}

.Navbar-wrapper .Wrap-menubar .Menubar-mobile .Menubar-item .searchbar {
	margin: 0px 16px;
}

.Navbar-wrapper .Wrap-menubar .Menubar .Dropdown-item {
	position: relative;
}

.Navbar-wrapper .Wrap-menubar .Menubar .Dropdown-item .downIcon {
	font-size: 12px;
	margin-top: 6px;
	margin-left: 6px;
}

.Navbar-wrapper .Wrap-menubar .Menubar .Dropdown-item .dropdown-container {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	width: 180px;
	transform: translateX(-30%);
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	padding: 8px 0px;
	z-index: 100;
}

.Navbar-wrapper
	.Wrap-menubar
	.Menubar
	.Dropdown-item
	.dropdown-container
	.dropdown-link {
	display: block;
	width: 100%;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 15px;
	color: #333;
	font-weight: bold;
    padding: 2px 8px;
}

.Navbar-wrapper
	.Wrap-menubar
	.Menubar
	.Dropdown-item:hover
	.dropdown-container {
	display: block;
}

.Navbar-wrapper
	.Wrap-menubar
	.Menubar
	.Dropdown-item
	.dropdown-container
	.dropdown-link:hover {
	background-color: #ccc;
}
