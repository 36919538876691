.StoryForm .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.StoryForm .btn-wrapper span {
  margin-left: 1rem;
}

.StoryForm .story-cover {
  background-color: #ffffff;
  width: 100%;
  max-height: 200px;
  margin-bottom: 20px;
}

.image-upload-wrap {
  border: 2px dashed #c0c7d1;
  overflow: hidden;
  max-height: 200px;

}

.image-upload-wrap:hover{
  cursor: pointer;
}

.image-placeholder {
  height: 50px;
  width: 50px;
  margin: auto;
  margin-bottom: 10px;
}

.story-cover .image-upload-wrap input {
  opacity: 0;
  width: 550px;
}

.story-cover .image-upload-wrap input:hover {
  cursor: pointer;
}

.story-cover .image-upload-wrap .drag-text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
}
.image-type {
  text-align: center;
  font-size: 12px;
  color: #c0c7d1;
}

.preview-image img{
  height: 60%;
  width: 100%;
  overflow: hidden;
  object-fit: contain;
}

.story-editor {
  margin-bottom: 10px;
}
.story-titles {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.StoryForm .add-url .input-wrap {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.StoryForm .add-url .input-wrap p {
  padding-right: 5px;
  margin-top: 5px;
  font-size: 20px;
}

@media all and (max-width: 991px) {
  .StoryForm .add-url .input-wrap p {
    padding-right: 5px;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
  }

  .preview-image img{
    width: 100%;
    height: 100%;
  }
}
