.DonationStep {
  width: 100%;
}

.DonationStep .input-wrapper:not(:first-child) {
  margin-top: 1rem;
}

.anonymous-checkbox {
  font-family: IBMPlexSansR;
  font-size: 12px;
  align-items: center;
}

.anonymous-checkbox label {
  margin-top: 10px;
}

.DonationStep .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.Error-message {
  text-align: center;
  font-weight: 500;
  color: var(--primaryYellowColor);
  margin-top: 1rem;
  transition: all 0.4s;
}
