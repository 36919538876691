.HeroAngelModal {
  height: calc(100vh - 87px);
  background-image: url("../../../../assets/images/heroAngelModelbg.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s;
}

.HeroAngelModal.causeHover {
  background-image: url("../../../../assets/images/createCausebg.jpg");
}

.HeroAngelModal.enterpriseHover {
  background-image: url("../../../../assets/images/createEnterprisebg.jpg");
}

.HeroAngelModal .option_wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  width: 50%;
}

.option_wrapper .option_title {
  font-size: 28px;
  font-weight: 600;
  padding: 0px 4rem;
  text-align: center;
}
.option_btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.option_btns div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 48%;
}

.option_btns .heroAngelModal__btns {
  width: 100%;
}

.option_btns div p {
  text-align: center;
  height: 40%;
}

@media (min-width: 1912px) {
  .option_btns div p {
    font-size: 18px;
  }
}

@media (max-width: 1214px) {
  .option_wrapper .option_title {
    font-size: 24px;
  }
}

@media (max-width: 1092px) {
  .option_wrapper .option_title {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .HeroAngelModal {
    background: #fff;
  }

  .HeroAngelModal .option_wrapper {
    padding: 10px;
    width: 80%;
  }

  .option_wrapper .option_title {
    font-size: 22px;
    font-weight: 500;
    padding: 0px 1rem;
  }

  .option_btns {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .option_btns div {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .HeroAngelModal {
    height: calc(100vh - 68px);
  }

  .option_wrapper .option_title {
    font-size: 18px;
  }

  .option_btns div {
    width: 90%;
  }
  .option_btns div p {
    text-align: center;
    height: 100%;
  }
}
