.SectionHeader p {
    margin-top: 14px;
    font-size: 15px;
}

@media all and (min-width: 450px) {
    .SectionHeader p {
        font-size: 16px;
    }
}
