.not-authenticated{
  color: #fff;
  border-radius: 5px;
  padding: 5px 5px;
  width: fit-content  ;
  font-size: 14px;
  text-align: center;
  background-color: var(--dashboardActive);
}

.no-comments{
  font-size: 14px;
  color: var(--darkGray);
}

.comments-section {
  margin: 10px 10px 0 10px;
}

.comment-reply {
  margin-left: 3rem;
}

.comment-inputWrap, .comment-editor{
  margin-bottom: 10px;

}

/*  SingleComment Styling  */

.single-commentTab {
  background-color: var(--lightbg);
  border-radius: 5px;
  position: relative;
}

.single-commentTab .comment-user {
  display: flex;
  flex-direction: row;
  margin-left: 0.825rem;
  padding-top: 10px;
}

.comment-user .commentUser-avatar {
  height: 50px;
  width: 50px;
}

.comment-user .commentUser-name {
  text-align: center;
  font-size: 14px;
  margin-top: 0.825rem;
  margin-left: 8px;
  font-weight:500;
}

.comment-content {
  margin: 10px 4em 10px 4em;
  padding-bottom: 2em; 
}

.comment-content p {
  font-size: 16px;
}

.comment-info {
  display: flex;
  position: absolute;
  top: 18px;
  right: 10px;
}

.comment-info p {
  font-size: 14px;
  color: var(--darkGray);
  margin-right: 5px;
  font-weight: 400;
}

.reply-btn button {
  color: #0067ff;
  border: none;
  background: none;
  font-size: 16px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.comment_ReplyBox{
  margin: 10px 0;
  
}

@media all and (max-width: 991px) {
  .comment-user .commentUser-avatar {
    height: 32px;
    width: 32px;
  }

  .comment-info {
    padding: 0 0 20px 30px;
    margin: 10px 0 10px 0;
    position: inherit;
  }

  .comment-content {
    margin: 10px 1em 10px 2em;
    padding-bottom: 0.524em;
  }
}
