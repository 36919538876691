.LabelledInput .Label {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  margin-left: 2px;
  margin-bottom: -1px;
}

.LabelledInput .InputField {
  display: block;
  width: 250px;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  resize: none;
  background-color: #fff;
}

.LabelledInput.autoWidth .InputField {
  width: auto;
}

.LabelledInput.fullWidth .InputField {
  width: 100%;
}

.LabelledInput .InputField:focus {
  outline-color: var(--primaryYellowColor);
}

.LabelledInput.hero .InputField:focus {
  outline-color: var(--primaryYellowColor);
}

.LabelledInput.angel .InputField:focus {
  outline-color: var(--primaryBlueColor);
}

.LabelledInput.storyImage .InputField {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.LabelledInput .InputField.inputIcon {
  background: url("../../../../assets//images/dollarsign.png") no-repeat scroll;
  padding-left: 50px;
}

@media all and (min-width: 350px) {
  .LabelledInput .Label {
    font-size: 16px;
  }

  .LabelledInput .InputField {
    width: 320px;
  }
}

@media all and (min-width: 500px) {
  .LabelledInput .InputField {
    width: 420px;
  }
}
