.StepsBar {
  position: relative;
}

.StepsBar .steps-list {
  display: flex;
}

.StepsBar .steps-list .divider {
  width: 150px;
  height: 5px;
  background-color: #d9e0ec;
  margin-top: 20px;
  margin-right: -40px;
  margin-left: -40px;
  z-index: 1;
}

.StepsBar.hero .steps-list .divider.activeDivider {
  background: rgb(39, 217, 131);
  background: linear-gradient(
    90deg,
    rgba(39, 217, 131, 1) 0%,
    rgba(39, 217, 131, 1) 49%,
    rgba(217, 224, 236, 1) 89%
  );
}

.StepsBar.hero .steps-list .divider.completed {
  background-color: #27d983;
}

.StepsBar.angel .steps-list .divider.completed {
  background-color: var(--primaryBlueColor);
}

.StepsBar .steps-list .step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StepsBar .steps-list .step-item .count {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: #000;
}

.StepsBar.hero .steps-list .step-item .count {
  background-color: #d9e0ec;
}

.StepsBar .steps-list .step-item .count.activeCount {
  background-color: #27d983;
  color: #fff;
}

.StepsBar.angel .steps-list .step-item .count {
  background-color: var(--primaryBlueColor);
}

.StepsBar .steps-list .step-item .label {
  margin-top: 8px;
  text-transform: uppercase;
  font-size: 14px;
}

.StepsBar.hero .steps-list .step-item .label {
  color: var(--black);
}

.StepsBar.angel .steps-list .step-item .label {
  color: var(--primaryBlueColor);
}

@media all and (max-width: 991px) {
  .StepsBar.hero .steps-list .step-item {
    display: flex;
    flex-direction: row;
  }

  .StepsBar.hero .steps-list .step-item .count {
    background-color: #27d983;
    color: #fff;
    margin-right: 5px;
  }
}

@media all and (max-width: 1190px) {
  .StepsBar .steps-list .divider {
    width: 120px;
  }
}

@media all and (max-width: 1070px) {
  .StepsBar .steps-list .divider {
    width: 100px;
  }
}
