.CharitiesList .title-wrapper {
	margin-bottom: 2.5rem;
	text-align: center;
}

.CharitiesList .card-wrapper {
	margin-bottom: 2.5rem;
}

.campaign_options{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 0px 30px;
}	

.campaign_options .campaign_sorting{
	display: inline;
}

.campaign_options .campaign_sorting select{
	height: 44px;
    border-radius: 5px;
    width: 150px;
    padding: 0px 5px;
    font-size: 14px;
    font-weight: 200;
	background-color: #ffffff;
	margin-left: 10px;
	border-color: #C0C7D1;
	color: #061024;
}

.campaign_options .campaign_layout{
	height: 44px;
	width: 90px;
	border: 1px solid #C0C7D1;
	border-radius: 8px;
	display: flex;
	color: var(--darkGray);
	background-color: #ffffff;
	font-size: 22px;
}

.campaign_options .campaign_layout:hover{
	cursor: pointer;
}

.campaign_layout .grid{
	width: 100%;
	height: 100%;
	border-radius: 8px;
	text-align: center;
}

.campaign_layout .list{
	width: 100%;
	height: 100%;
	text-align: center;
}


.campaign_layout .grid.active, .campaign_layout .list.active{
	color:#0067FF;
	background-color: #D9E0EC;
	border-radius: 8px;
}

.campaign_layout .grid.active{
	border-right: 1px solid #C0C7D1;
}

.campaign_layout .list.active{
	border-left: 1px solid #C0C7D1;
}




/* list layout */
.list-layout{
	border: 1px solid #D9E0EC;
	background-color: #ffffff;
	padding: 0px 12px;

}

.header_row{
	background-color: #F9FAFF;
	height: 44px;
	border-bottom: 1px solid #D9E0EC;
	padding: 0px 0px;
}

.header_row .headings{
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	margin: auto;
}

.projectCard_list{
	display: flex;
	flex-direction: column;
}


.pagination-wrapper{
	display: flex;
	justify-content: center;
	margin-top: 1rem;
}

@media all and (max-width: 991px){
	.campaign_options .campaign_sorting select {
		
		width: 240px;
	}	
}