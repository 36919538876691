@font-face {
  font-family: IBMPlexSansRegular;
  src: url("../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");
}

@font-face {
  font-family: IBMPlexSansR;
  src: url("../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}

.notlogged {
  background-color: #d9e0ec;
  min-height: 100vh;
  padding-bottom: 2rem;
}

.CampaignDonation {
  margin: 2rem 26% 1rem 26%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
}

@media all and (max-width: 991px) {
  .CampaignDonation {
    margin: 2rem 0 0 0;
  }
}

@media all and (min-width: 2250px) {
  .CampaignDonation {
    height: 85vh;
  }
}
