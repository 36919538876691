.heroFlowStep .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .heroFlowStep .btn-wrapper span {
    margin-left: 1rem;
  }

.heroFlowStep .input-wrapper{
    margin-bottom: 1rem;
}  
  