.FluidCard .row > .col {
    padding: 0;
}

.FluidCard .FluidCard-Wrap {
    position: relative;
    display: flex;
    height: 70vh;
}

.FluidCard .FluidCard-Wrap .head .card-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    z-index: 1;
}

.FluidCard .FluidCard-Wrap .head .card-bg-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.FluidCard .FluidCard-Wrap .head .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    z-index: 2;
}

.FluidCard .FluidCard-Wrap .body {
    position: relative;
    align-self: flex-end;
    margin-left: 20px;
    margin-bottom: 20px;
    z-index: 3;
}

@media all and (min-width: 567px) {
    .FluidCard .FluidCard-Wrap,
    .FluidCard .FluidCard-Wrap .head .card-bg-img,
    .FluidCard .FluidCard-Wrap .head .card-bg-img img,
    .FluidCard .FluidCard-Wrap .head .overlay {
        border-radius: 8px;
    }
}

@media all and (min-width: 992px) {
    .FluidCard .FluidCard-Wrap,
    .FluidCard .FluidCard-Wrap .head .card-bg-img,
    .FluidCard .FluidCard-Wrap .head .overlay {
        height: 80vh;
    }
}

@media all and (min-width: 1200px) {
    .FluidCard .FluidCard-Wrap,
    .FluidCard .FluidCard-Wrap .head .card-bg-img,
    .FluidCard .FluidCard-Wrap .head .overlay {
        height: 90vh;
    }
}
