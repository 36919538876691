@font-face {
  font-family: IBMPlexSansRegular;
  src: url("../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");
}

@font-face {
  font-family: IBMPlexSansR;
  src: url("../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}

.DashboardLayout {
  background-color: var(--lightbg);
  font-family: IBMPlexSansRegular;
}

.DashboardLayout .LeftSide {
  padding-left: 0px !important;
}

.DashboardLayout .RightSide .wrap-body {
  margin: 1rem 0px;
}

/* create cause/ enterprise styling */

.CreateCause,
.CreateEnterprise {
  margin: 0 30px;
  /* height: 100vh;   */
}

.CreateCause .createcause-wrap,
.CreateEnterprise .createcause-wrap {
  height: 100%;
}

.CreateCause .createCause-left,
.CreateEnterprise .createCause-left {
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.CreateCause .createCause-right,
.CreateEnterprise .createCause-right {
  background-image: url("../../../assets/images/heroCause.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media all and (max-width: 991px) {
  .CreateCause .createCause-left,
  .CreateEnterprise .createCause-left {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .CreateCause .cause-mobile-only,
  .CreateEnterprise .cause-mobile-only {
    background-image: url("../../../assets//images//herocause-mob.png");
    background-size: cover;
    height: 400px;
    width: 100%;
    overflow: hidden;
  }

  .DashboardLayout .RightSide .wrap-body {
    margin: 0px 0px;
  }

  .CreateCause,
  .CreateEnterprise {
    margin: 0px;
  }
}

@media all and (min-width: 2250px) {
  .CreateCause .createCause-left,
  .CreateEnterprise .createCause-left {
    height: 85vh;
  }

  .CreateCause .createCause-right,
  .CreateEnterprise .createCause-right {
    height: 85vh;
  }
}
