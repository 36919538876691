.ProjectCardList {
	font-family: IBMPlexSansR;
	cursor: pointer;
	height: 88px;
	border-bottom: 1px solid #D9E0EC ;
}


.ProjectCardList .project_item{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: auto;
	text-transform: capitalize;
	font-size: 14px;
		
}

.ProjectCardList .category-icon{
	padding-top: 3px;
	text-align: center;
	color: #0067FF;
	background: #F2F5FB;
	border-radius: 50%;
	height: 48px;
	width: 48px;
	align-items: center;
	font-size: 25px;
	font-weight: 100;
	margin-right: 10px;
}

.ProjectCardList .image{
	height: 72px;
	width: 120px;
}

.ProjectCardList .image img{
	height: 100%;
	width: 100%;
	border-radius: 8px;
}


.ProjectCardList .progress {
	height: 12px;
	margin: 0px 0px;
	background-color: #C0C7D1;
	border-radius: 20px;
}


.card-progressbar .progress .progress-bar  {
	background-color: #0067FF;
}

.progress.complete .progress-bar{
	background-color: #27D983;
}


