.impact-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.impactForm .radio-values .values-list {
  position: relative;
  left: 46%;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
}

.impactForm .radio-values .values-list li {
  margin-right: 38px;
  font-size: 12px;
  font-weight: bold;
}

.impactForm .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.impactForm .btn-wrapper span {
  margin-left: 1rem;
}

@media all and (max-width: 991px) {
  .impactForm .radio-values {
    display: none;
  }

  .addCause-icon {
    display: none;
  }
}

@media all and (min-width: 1530px) {
  .impactForm .radio-values .values-list {
    left: 50%;
  }

  .impactForm .radio-values .values-list li {
    margin-right: 48px;
  }
}

@media all and (min-width: 1720px) {
  .impactForm .radio-values .values-list {
    /* left: 52%; */
    transform: translateX(20px);
  }
  .impactForm .radio-values .values-list li {
    margin-right: 58px;
  }
}

@media all and (min-width: 1940px) {
  .impactForm .radio-values .values-list li {
    margin-right: 9%;
  }
}
