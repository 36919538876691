.rewards-top .add-reward{
    margin-left: 0%;
    float: right;
    margin-right: 15px;
  }

  .rewards-top .rewards-heading {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 0 1.235rem;
    padding-top: 1.245rem;
  }

  .reward-description{
    border-bottom: 1px solid #E2E8F3;
    font-size: 12px;
    font-family: IBMPlexSansR;
    font-weight: 400;
    padding: 0px 10px;
    color: var(--darkGray) !important;
    margin-bottom: .5rem;
   
  }

  .reward-quantity{
    border-bottom: 1px solid #E2E8F3;
    padding: 0 10px;
  }

  .reward-quantity .quantity-headings, .reward-quantity .quantity-values{
    margin-bottom: .5rem;
  }
  .reward-quantity .quantity-headings, .reward-bottom .rewards-heading{
    font-size: 12px;
    font-weight: bold;
    color: var(--darkGray) !important;
  }

  .reward-quantity .quantity-values{
    font-size: 14px;
    font-weight: 400;
    font-family: IBMPlexSansR;
    color: var(--black) !important;
  }

  .reward-bottom{
    padding: 8px 10px;
  }

  .reward-bottom .rewards-heading{
    display: inline;
  }

  .reward-bottom .edit-reward{
    border: none;
    background: none ;
    color: var(--dashboardActive);
    font-size: 14px;
    font-weight: 500;
    float: right;
    display: inline;
  }
