.LoginMobile {
    width: 100vw;
    min-height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginMobile .LoginMobile-Section {
    flex: 1;
}

.LoginMobile .LoginMobile-Body .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LoginMobile .LoginMobile-Body .header .header-times {
    font-size: 2.5rem;
    color: #eb982f;
}

.LoginMobile .LoginMobile-Body .form .wrap-input label {
    color: #fff;
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: -2px;
    margin-top: 20px;
}

.LoginMobile .LoginMobile-Body .form .other-options {
    margin-top: 16px;
}

.LoginMobile .LoginMobile-Body .form .other-options .forgotLink {
    text-decoration: none;
    color: #eb982f;
    text-transform: uppercase;
    font-size: 0.875rem;
}

.LoginMobile .LoginMobile-Body .form .other-options .forgotLink:hover {
    text-decoration: underline;
}

.LoginMobile .LoginMobile-Body .form .other-options .login-checkBox {
    display: flex;
    margin-top: 8px;
}

.LoginMobile .LoginMobile-Body .form .other-options .login-checkBox input {
    width: 18px;
    height: 18px;
}

.LoginMobile .LoginMobile-Body .form .other-options .login-checkBox label {
    color: #fff;
    font-weight: 500;
    margin-top: -2px;
    margin-left: 8px;
}

.LoginMobile .LoginMobile-Body .form .group-buttons {
    margin-top: 24px;
}
