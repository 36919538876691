.CollapseCard {
	height: auto;
	margin-bottom: 1rem;
	border-bottom: 1px solid #e6e6e6;
}

.CollapseCard .Title {
    width: 100%;
	word-wrap: break-word;
}

.CollapseCard .CollapseCard-Head {
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.CollapseCard .CollapseCard-Head .wrap-arrow {
	margin-left: 1rem;
	cursor: pointer;
	transition: all 300ms ease;
}

.CollapseCard .CollapseCard-Body {
	transition: all 300ms ease;
	transform-origin: center top;
}

.CollapseCard .CollapseCard-Body p {
	color: #4f4f4f;
}

.CollapseCard.false {
	transition: all 300ms ease;
}

.CollapseCard.true {
	transition: all 300ms ease;
}

.CollapseCard.false > .CollapseCard-Body {
	transform: scaleY(0);
}

.CollapseCard.true > .CollapseCard-Body {
	transform: scaleY(1);
}



/* collapse-reward mobile styles */
.CollapseCard.collapse-reward{
	margin: 12px 10px;
	background-color:#F9FAFF ;
	border: 1px solid #E2E8F3;
	border-radius: 10px;
}

.CollapseCard.collapse-reward .CollapseCard-Head{
	margin-bottom: 0px;
	align-items: unset;
	padding: 0 10px;
	margin: 5px 0px
	
}

.CollapseCard.collapse-reward .CollapseCard-Head .wrap-title p{
	margin-bottom: 0.5rem;
	font-size: 16px;
	font-weight: 500;
}
