.impact-value {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.impact-name {
  display: flex;
  flex: row;
  justify-content: flex-start;
}

.impact-name .impact-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 2px 10px 0 10px;
}

.impact-name p {
  font-size: 12px;
  color: var(--darkGray);
}

.impact-value .impact-points {
  font-size: 14px;
  margin-left: 30px;
}

@media all and (max-width: 991px) {
  .impact-value {
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
    margin-left: 5px;
  }

  .impact-value .impact-points {
    margin-right: 15px;
  }
}
