.HorizontalLine {
    display: flex;
    align-items: center;
    margin: 14px 0px;
}

.HorizontalLine .line {
    height: 1px;
    width: 100%;
    background-color: #969696;
}

.HorizontalLine.white .line {
    background-color: #fff;
}

.HorizontalLine .text {
    margin: 0px 14px;
    font-size: 14px;
}

.HorizontalLine.white .text {
    color: #fff;
}
