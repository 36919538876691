.updateCauseModal .modal-top {
  margin-top: 10px;
}

.updateCauseModal .modal-top .modal-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.updateCauseModal .modal-top .close-btn {
  border: none;
  background: none;
  float: right;
  position: absolute;
  top: 10px;
  right: 1.25rem;
  color: var(--darkGray);
  font-size: 20px;
}

.updateCauseModal .modal-main-body {
  margin: 10px 0px 10px 10px;
}

.updateCauseModal .modal-main-body .updateCause-left .modal-image {
  height: 224px;
  width: 300px;
}
.updateCauseModal .modal-main-body .updateCause-left .modal-image img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.modal-main-body .updateCause-left .add-delete-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-delete-image .image-input-wrapper {
  display: none;
}

.updateCause-left .add-delete-image .add-image {
  color: #0085ff;
  font-size: 14px;
  font-weight: 500;
}

.updateCause-left .add-delete-image .add-image:hover {
  cursor: pointer;
}

.updateCause-left .add-delete-image .delete-image {
  font-size: 14px;
  font-weight: 500;
  color: #c0c7d1;
}

.updateCause-left .add-delete-image .delete-image:hover {
  cursor: pointer;
}

.modal-main-body .updateCause-right {
  padding-left: 10px;
}

.updateCause-right .update-input-wrapper {
  margin-bottom: 10px;
}

.update-input-wrapper .LabelledInput .Label,
.updateCause-left .labelledInput {
  font-size: 14px;
  font-weight: 500;
}

.fileSize-note {
  font-size: 12px;
  color: var(--darkGray);
  text-align: center;
  margin-top: 0.45rem;
}

.fileSize-note span {
  font-weight: bold;
  font-style: normal;
}

/* production stage */
.product-stage .radio-tools:checked + label,
.radio-tools:not(:checked) + label {
  font-weight: 500;
  letter-spacing: 0px;
  font-size: 13px;
}

.save-changes-btn {
  display: flex;
  justify-content: center;
  margin: 10px 0 18px 0;
}

/* share modal */
.social-share-buttons {
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.social-share-buttons .share-btn-wrapper {
  margin-right: 10px;
}

.link-copy-wrapper {
  margin-top: 1.5rem;
}

.link-copy-wrapper .link-copy-text,
.link-to-copy {
  font-size: 14px;
  color: var(--darkGray);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;
}

.link-copy-wrapper .link-to-copy {
  width: 80%;
}

.link-copy-wrapper .link-box {
  display: flex;
  background: #f1f1f1;
  align-items: center;
  justify-content: space-around;
  height: 40px;
}

.link-box .copy-btn {
  color: #0085ff;
  font-size: 14px;
  padding-left: 5px;
  margin-bottom: 0px;
  color: "#0085FF";
}

.link-box .copy-btn:hover {
  cursor: pointer;
}

@media all and (max-width: 991px) {
  .updateCauseModal .modal-main-body {
    justify-content: center;
  }

  .updateCauseModal .modal-main-body .updateCause-left .modal-image {
    width: 100%;
    height: auto;
  }

  .modal-main-body .updateCause-left .add-delete-image {
    margin-top: 10px;
  }
}
