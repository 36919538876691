.SelectedChatUser {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 12px 0px 12px 0px;
}

.SelectedChatUser .wrap-user {
	display: flex;
	align-items: center;
}

.SelectedChatUser .wrap-user .wrap-img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.SelectedChatUser .wrap-user .wrap-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
	border-radius: 50%;
}

.SelectedChatUser .wrap-user .name {
	font-weight: 1.25rem;
	margin-left: 12px;
	font-weight: 500;
}

.SelectedChatUser .wrap-user .last-message {
	font-size: 14px;
	margin-left: 16px;
	color: rgba(0, 0, 0, 0.4);
}

.SelectedChatUser .wrap-button button {
	border: none !important;
	outline: none !important;
	background-color: transparent;
	color: rgba(0, 0, 0, 0.4);
}

.SelectedChatUser .wrap-button button::after {
	display: none;
}
