.EnterpriseForm .wrapper .wrap-bar {
	width: fit-content;
	margin: 1rem auto 2rem auto;
}

.btn-wrapper {
  margin-bottom: 2rem;
}

.enterprise-title {
	align-items: center;
	text-align: center;
	margin-bottom: 0.645rem;
	font-size: 32px;
	font-weight: 400;
}

@media (max-width: 991px) {
	.enterprise-title {
	  display: none;
	}
  }
