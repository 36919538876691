.marker-filter-wrapper {
  position: absolute;
  transform: translate(-350px, -235px);
}

@media all and (max-width: 991px) {
  .marker-filter-wrapper {
    transform: translate(-140px, -310px);
  }
}

@media all and (max-width: 780px) {
  .marker-filter-wrapper {
    transform: translate(-140px, -220px);
  }
}

@media all and (max-width: 375px) {
  .marker-filter-wrapper {
    transform: translate(-140px, -310px);
  }
}

@media all and ((min-height: 880px) and (min-width: 991px)) {
  .marker-filter-wrapper {
    transform: translate(-350px, -310px);
  }
}

@media all and (min-height: 1100px) {
  .marker-filter-wrapper {
    transform: translate(-350px, -380px);
  }
}

@media all and (min-height: 1500px) {
  .marker-filter-wrapper {
    transform: translate(-350px, -490px);
  }
}

@media all and (min-height: 2000px) {
  .marker-filter-wrapper {
    transform: translate(-350px, -540px);
  }
}
