/* StyleSheet */
@font-face {
  font-family: MontserratRegular;
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryBlueColor: #3b918f;
  --primaryYellowColor: #ec972f;
  --secondaryBlueColor: #2fa398;
  --primaryGreyColor: #666666;
  --darkGray: #858794;
  --halfWhite: #f2f1f0;
  --spanishGrey: #909090;
  --dashboardNavItems: #393d4e;
  --dashboardName: #080c22;
  --black: #080c22;
  --dashboardActive: #0067ff;
  --lightbg: #f2f5fb;
  --linearBlue: #0085ff;
  --dashboardbtnLinear: #0085ff;
  --violet: #727dca;
  
}

body {
  font-family: MontserratRegular;
  overflow-x: hidden;
}
