.TribePrimaryBtn {
    width: 100%;
    border: none;
    border-radius: 6px;
    outline: none !important;
    padding: 8px 0px;
    color: #fff;
    font-weight: bold;
}

.TribePrimaryBtn.applyFlex {
    display: flex;
    align-items: center;
    padding: 8px 14px;
}

.TribePrimaryBtn.applyFlex .text {
    margin: auto;
}

@media all and (max-width: 450px) {
    .TribePrimaryBtn {
        font-size: 0.875rem;
    }

    .TribePrimaryBtn.applyFlex {
        padding: 8px;
    }
}
