.TribeLogo {
    width: 48px;
    height: 48px;
}

.TribeLogo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media all and (min-width: 768px) {
    .TribeLogo {
        width: 72px;
        height: 72px;
    }
}
