.PrimaryCard {
    margin: auto;
    max-width: 350px;
    border-radius: 8px;
    background-color: #fafafa;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.PrimaryCard .PrimaryCard-Head {
    height: 200px;
}

.PrimaryCard .PrimaryCard-Head img {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
}

.PrimaryCard .PrimaryCard-Body {
    padding: 1rem;
}

.PrimaryCard .PrimaryCard-Body .text {
    color: #333;
    font-weight: 400;
    margin-top: 0.5rem;
}

.PrimaryCard .PrimaryCard-Body .btn-box .link-button {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #eb982f;
}

.PrimaryCard .PrimaryCard-Body .btn-box .link-button .link-icon {
    margin-left: 6px;
}

@media all and (min-width: 992px) {
    .PrimaryCard {
        max-width: 380px;
    }
}
