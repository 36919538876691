.UserCard {
    width: 285px;
    padding: 12px 14px;
    margin: auto;
    background-color: #fafafa;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.UserCard .UserCard-body .user-img {
    width: 67px;
    height: 67px;
    border-radius: 50%;
}

.UserCard .UserCard-body .user-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    border-radius: 50%;
}

.UserCard .UserCard-body .content .location {
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
}

.UserCard .UserCard-body .content .divider {
    width: 100%;
    height: 1px;
    background: #e6e6e6;
    margin: 8px 0px;
}

.UserCard .UserCard-body .content .contributions-list {
    margin-top: 1rem;
}

.UserCard .UserCard-body .content .contributions-list .contributions-list-item {
    width: 175px;
    display: flex;
    justify-content: space-between;
    margin: 14px 0px;
}

.UserCard
    .UserCard-body
    .content
    .contributions-list
    .contributions-list-item
    p {
    margin: 0;
    font-size: 0.875rem;
}

.UserCard
    .UserCard-body
    .content
    .contributions-list
    .contributions-list-item
    .key {
    font-weight: bold;
    text-transform: uppercase;
}

.UserCard .UserCard-footer {
    margin-top: 1.25rem;
    margin-bottom: 0.25rem;
}

.UserCard .UserCard-footer .footer-btn {
    display: inline-block;
    text-decoration: none;
    color: #ec972f;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}

.UserCard .UserCard-footer .footer-btn .icon {
    padding-left: 6px;
}

@media all and (min-width: 768px) {
    .UserCard {
        width: 320px;
        padding: 1rem 1.25rem;
    }

    .UserCard .UserCard-body {
        display: flex;
    }

    .UserCard .UserCard-body .user-img {
        width: 80px;
        height: 80px;
    }

    .UserCard .UserCard-body .content {
        flex: 1;
        margin-left: 25px;
    }

    .UserCard .UserCard-footer {
        display: flex;
        justify-content: center;
    }
}

@media all and (min-width: 992px) {
    .UserCard {
        width: 350px;
        padding: 1rem 1.25rem;
    }

    .UserCard .UserCard-body {
        display: flex;
    }

    .UserCard .UserCard-body .user-img {
        width: 100px;
        height: 100px;
    }

    .UserCard .UserCard-body .content {
        flex: 1;
        margin-left: 25px;
    }

    .UserCard .UserCard-footer {
        display: flex;
        justify-content: center;
    }
}
