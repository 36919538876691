@font-face {
    font-family: IBMPlexSansR;
    src: url("../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
  }
  

.pagination{
    font-family: IBMPlexSansR;    

}

.pagination li{
    margin: 0.456rem;
    
}

.pagination li:hover{
    cursor: pointer;
}

.pagination  .page-list-item.active{
    color: #0067FF
}

.pagination .change-btns{
    background-color: #D9E0EC;
    height: 24px;
    width: 24px;
    text-align: center;
    border: none;

}