
.donations-section {
  margin: 10px 20px;
}



.donations-section .donations-table {
  border-collapse: collapse;
  width: 100%;
}

.donations-section .donations-table tr {
  border-bottom: 1px solid #e2e8f3;
}

.donations-table tr.table-headings {
  background-color: #f2f5fb;
  font-size: 14px;
  font-weight: 400;
  border: none;
}


.donations-section .donations-table tr td{
  font-family: IBMPlexSansR;
}

.donations-section .donations-table tr td,
.donations-section .donations-table tr th {
  padding: 8px;
  font-size: 14px;
  color: #080c22;
  
}




.donations-section .donations-table tr th {
  font-weight: 100;
}
.donations-section .donations-table tr th span {
  margin-left: 10px;
}

/* mobile only */

.donation-wrap {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

.donation-wrap .donation-left {
  flex: 90;
}

.donation-wrap .donation-right {
  flex: 10;
  padding-left: 20px;
}

.donation-top,
.donation-bottom {
  line-height: 10px;
  display: flex;
  justify-content: space-between;
}

.donar-message {
  margin-right: 5px;
}

.donation-top .donar-name {
  font-size: 16px;
}

.donation-top .amount-donated {
  font-size: 14px;
}

.donation-bottom .donar-message,
.donation-bottom .donation-date {
  font-size: 12px;
  font-weight: 100;
  color: var(--darkGray);
}

.donations-section.mobile {
  display: none;
}

@media all and (max-width: 991px) {
  .donations-section.desktop {
    display: none;
  }

  .donations-section.mobile {
    display: block;
  }

  .donations-section  {
    background-color: #f9faff;
    border-radius: 10px;
    border: 1px solid #d9e0ec;
  }

  .donation-right {
    font-size: 20px;
    color: #0067ff;
  }
}
