.NewsPage .NewsPage-Header {
    margin-top: 80px;
    margin-bottom: 40px;
}

.NewsPage .NewsPage-Header .text {
    margin-top: 16px;
    margin-bottom: 30px;
}

.NewsPage .NewsPage-Header .btns-group .group {
    display: flex;
}

.NewsPage .NewsPage-Header .btns-group > :last-child {
    margin-top: 12px;
}

.NewsPage .NewsPage-Header .btns-group .group > :last-child {
    margin-left: 0.8rem;
}

.NewsPage .NewsPage-Body {
    margin-bottom: 60px;
}

@media all and (min-width: 500px) {
    .NewsPage .NewsPage-Header .btns-group {
        display: flex;
    }

    .NewsPage .NewsPage-Header .btns-group > :last-child,
    .NewsPage .NewsPage-Header .btns-group .group > :last-child {
        margin: 0px;
    }

    .NewsPage .NewsPage-Header .btns-group .group-one > :last-child,
    .NewsPage .NewsPage-Header .btns-group .group-second > :nth-child(1),
    .NewsPage .NewsPage-Header .btns-group .group-second > :nth-child(2) {
        margin-left: 0.8rem;
    }
}
