#LanguageBar .LanguageDropdown {
    margin-top: 0.5rem;
    margin-right: 1.5rem;
}

#LanguageBar .LanguageDropdown .DropdownSelected {
    display: flex;
}

#LanguageBar .LanguageDropdown .DropdownOptions {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#LanguageBar .LanguageDropdown .flagBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

#LanguageBar .LanguageDropdown .flagBox span {
    margin-top: 2px;
    margin-left: 6px;
    font-size: 14px;
}

#LanguageBar .LanguageDropdown .flagBox.hoverEffect {
    margin: 2px 0px;
}

#LanguageBar .LanguageDropdown .flagBox.hoverEffect:hover {
    cursor: pointer;
    text-decoration: underline;
}

#LanguageBar .LanguageDropdown .DropdownSelected .arrow {
    cursor: pointer;
    margin-left: 0.5rem;
}
