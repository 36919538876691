.icon-radio-btn [type="radio"]:checked,
.icon-radio-btn [type="radio"]:not(:checked){
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}

.radio-tools:checked + label,
.radio-tools:not(:checked) + label{
	position: relative;
	padding: 15px 18px;
	width: 100%;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 300;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 10px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	color :var(--darkGray);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
	border: 1px solid #C0C7D1;
}

.radio-tools:checked + label{
	background-color: #EAF3FF;
	color: var(--dashboardActive);
}

.radio-tools:checked + label::before,
.radio-tools:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-image: linear-gradient(298deg, red, yellow);
	z-index: -1;
}


    .for-radio-tools{
        display: flex;
		line-height: 15px;
		justify-content: center;
		
 }

 .for-radio-tools .radio-icon{
	 margin-right: 10px;
 }