.Home .First-FluidCard .FluidCard {
  margin-top: 150px;
  margin-bottom: 20px;
}

.Home .First-UserCardSlider {
  margin-top: 75px;
}

.Home .Second-UserCardSlider {
  margin-top: 150px;
  margin-bottom: 150px;
}

.Home .Second-FluidCard .FluidCard {
  margin-bottom: 80px;
}
