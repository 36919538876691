.SignupMobile {
	width: 100vw;
	min-height: 100vh;
	background-color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.SignupMobile .SignupMobile-Section {
	flex: 1;
}

.SignupMobile .SignupMobile-Body .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.SignupMobile .SignupMobile-Body .header .header-times {
	font-size: 2.5rem;
	color: #eb982f;
}

.SignupMobile .SignupMobile-Body .form .wrap-input label {
	color: #fff;
	font-weight: 500;
	font-size: 1.125rem;
	margin-bottom: -2px;
	margin-top: 20px;
}

.SignupMobile .SignupMobile-Body .form .other-options {
	margin-top: 16px;
}

.SignupMobile .SignupMobile-Body .form .other-options .forgotLink {
	text-decoration: none;
	color: #eb982f;
	text-transform: uppercase;
	font-size: 0.875rem;
}

.SignupMobile .SignupMobile-Body .form .other-options .forgotLink:hover {
	text-decoration: underline;
}

.SignupMobile .SignupMobile-Body .form .other-options .login-checkBox {
	display: flex;
	margin-top: 8px;
}

.SignupMobile .SignupMobile-Body .form .other-options .login-checkBox input {
	width: 18px;
	height: 18px;
}

.SignupMobile .SignupMobile-Body .form .other-options .login-checkBox label {
	color: #fff;
	font-weight: 500;
	margin-top: -2px;
	margin-left: 8px;
}

.SignupMobile .SignupMobile-Body .form .group-buttons {
	margin-top: 24px;
}

.SignupMobile .SignupMobile-Body .form {
	margin-top: 20px;
}

.SignupMobile .SignupMobile-Body .screenOne-text {
	text-align: center;
	color: #fff;
	font-size: 1.125rem;
}

.SignupMobile .SignupMobile-Body .parallel-btns {
	display: flex;
}

.SignupMobile .SignupMobile-Body .parallel-btns > * {
	flex: 1;
}

.SignupMobile .SignupMobile-Body .title-screenFour {
	font-weight: bold;
	text-align: center;
	color: #fff;
	margin-bottom: 1rem;
}

.SignupMobile .SignupMobile-Body .center-img {
	display: flex;
	justify-content: center;
	align-items: center;
}

.SignupMobile .SignupMobile-Body .center-img .wrap-dummyImg {
	width: 120px;
	height: 120px;
}

.SignupMobile .SignupMobile-Body .center-img .wrap-dummyImg img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
