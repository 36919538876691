@font-face {
  font-family: IBMPlexSansRegular;
  src: url("../../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");
}

.notlogged-detailsPage {
  background-color: var(--lightbg);
  font-family: IBMPlexSansRegular;
  padding: 0px 100px;
  background-color: #f4f9fd;
}

.notlogged-detailsPage .navbar-wrap {
  margin-bottom: 10px;
}


.preview-details {
  margin: 0 10px 0 10px;
}

.previewDetails-right {
  background-color: #ffffff;
  border-radius: 10px;
  max-height: 1050px;
  overflow: hidden;
}

.DetailsAmount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.WorldImpact-section{
  margin-top: 1rem;
}

@media all and (max-width: 991px) {
  .previewDetails-left {
    margin-bottom: 1.635rem;
  }

  .DetailsAmount {
    flex-direction: column;
    flex-grow: initial;
  }

  .notlogged-detailsPage {
    padding: 0px 10px;
  }
}
