.AuthModal .iconBtn {
    margin-left: 8px;
    border: none !important;
    outline: none !important;
    background: transparent;
    color: #979797;
    transition: all 200ms ease;
}

.AuthModal .iconBtn:hover {
    color: #000;
}

.AuthModal .modal-content {
    width: 90%;
    margin: auto;
    border-radius: 0.8rem;
}

.AuthModal .modal-header {
    padding: 0.8rem 0.5rem 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.AuthModal .modal-header .modal-title {
    margin: auto;
    color: #000;
    font-weight: bold;
}

.AuthModal .modal-header .close {
    padding: 0;
    margin: 0px 0px 0px 8px;
}
