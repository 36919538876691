.detailsSummary-wrap {
  margin-top: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  margin-right: 10px;
}

.detailsSummary-wrap .summary-tabs .summarytabs-list {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-left: 0px;
}

.summary-tabs .summarytabs-list li {
  list-style: none;
  border-bottom: 2px solid #e2e8f3;
  width: 100%;
  padding: 10px 0 10px 0px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.summary-tabs .summarytabs-list li.active {
  border-color: #0067ff;
  color: #0067ff;
}

.summary-content .tabWrap {
  padding: 0 20px 0 10px;
}

.summary-content {
  padding-bottom: 10px;
}


/*  */




@media all and (max-width: 991px){

  .detailsSummary-wrap .CollapseCard .CollapseCard-Head{
    height: 30px;
    margin: 10px 0px 10px 0;

  }

  .detailsSummary-wrap .CollapseCard {
    width: 95%;
    margin: auto;
  }
  
  .detailsSummary-wrap .CollapseCard .Title{
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
  }
  

  
}