.TrendingProjects {
	margin-bottom: 6rem;
}

.TrendingProjects .TrendingProjects-body {
	margin-top: 2rem;
}

@media all and (min-width: 1300px) {
	.TrendingProjects .TrendingProjects-body .container {
		max-width: 1200px;
	}
}

@media all and (min-width: 1500px) {
	.TrendingProjects .TrendingProjects-body .container {
		max-width: 1300px;
	}
}
