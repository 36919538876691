.causedetails-main {
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.causedetails-main .cause-image {
  overflow: hidden;
  height: 18rem;
  flex: 1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.causedetails-main .cause-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.causedetails-main .causedetails-description {
  flex: 1;
  margin: 2rem 1.635rem 0 1.625rem;
}

.causedetails-description .causedescription-top {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.causedetails-description .causedescription-top .cause-category {
  font-size: 15px;
  background-color: #eaf3ff;
  padding: 0 5px 0 5px;
  border-radius: 5px;
}

.causedescription-top .causedescription-buttons .button,
.morebtn-icon {
  border: none;
  background: none;
  color: var(--violet);
  margin-left: 10px;
  font-weight: lighter;
}

.causedetails-description .causedetails-wrap .causedetails-title {
  font-weight: 600;
  font-size: 25px;
  line-height: 15px;
  text-transform: capitalize;
}

.causedetails-description .causedetails-wrap .causedescription-text {
  font-size: 14px;
  color: #080c22;
  font-weight: 200;
}

.causedetails-description .causedetails-wrap .cause-location {
  font-size: 14px;
}

.causedetails-description .causedetails-wrap .cause-location span {
  color: #0067ff;
  text-transform: capitalize;
}

.causedetails-description .cause-primarybtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}

.morebtn-icon {
  color: #0067ff;
  font-size: 1.545rem;
  margin-top: 4px;
  line-height: 10px;
}

@media all and (max-width: 991px) {
  .causedetails-main {
    flex-direction: column;
    margin-top: 2rem;
    position: relative;
  }

  .causedetails-main .cause-image {
    flex: 100%;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
  }

  .causedetails-main .causedetails-description {
    flex: 100%;
  }
  .causedetails-description .cause-primarybtns {
    margin: 1rem 5px 1rem 0;
  }

  .causedescription-top .causedescription-buttons {
    position: absolute;
    top: 2%;
    right: 3%;
  }

  .causedescription-top .causedescription-buttons .button {
    background-color: #ffffff;
    border-radius: 5px;
    height: 28px;
    width: 28px;
  }
}
