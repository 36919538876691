#SliderNavbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	z-index: 999;
	transform-origin: left;
	transform: scaleX(0);
	transition: all 300ms ease;
	background-color: #000;
}

#SliderNavbar .wrapper {
	height: 99vh;
}

#SliderNavbar .wrapper .header-bar {
	margin-top: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#SliderNavbar .wrapper .header-bar .close-slider {
	cursor: pointer;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
}

#SliderNavbar .wrapper .body {
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #fff;
}

#SliderNavbar .wrapper .body .menubar {
	margin-top: 50px;
}

#SliderNavbar .wrapper .body .menubar ul {
	list-style: none;
}

#SliderNavbar .wrapper .body .menubar ul .menubar-item {
	margin: 20px 0px;
}

#SliderNavbar .wrapper .body .menubar ul .menubar-item:nth-child(1),
#SliderNavbar .wrapper .body .menubar ul .menubar-item:nth-child(2),
#SliderNavbar .wrapper .body .menubar ul .menubar-item:nth-child(3) {
	border-bottom: 1px solid #fff;
}

#SliderNavbar .wrapper .body .menubar ul .menubar-item.btn-item {
	margin-top: 40px;
}

#SliderNavbar .wrapper .body .menubar ul .menubar-item .menubar-link {
	display: block;
	cursor: pointer;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
	font-size: 20px;
}

#SliderNavbar .wrapper .body .footer .media-icons {
	display: flex;
	margin-bottom: 10px;
}

#SliderNavbar .wrapper .body .footer .media-icons a {
	cursor: pointer;
	display: inline-block;
	color: #fff;
	text-decoration: none;
	font-size: 1.75rem;
}

#SliderNavbar .wrapper .body .footer .media-icons a:not(:first-child) {
	margin-left: 20px;
}

#SliderNavbar .wrapper .body .menubar ul .dropdown-mobile .menubar-link {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

#SliderNavbar .wrapper .body .menubar ul .dropdown-mobile .dropdown-container {
	margin-left: 1.5rem;
	margin-bottom: 0.5rem;
	height: 0;
	transform: scaleY(0);
	transform-origin: center top;
	transition: all 300ms ease;
}

#SliderNavbar
	.wrapper
	.body
	.menubar
	ul
	.dropdown-mobile
	.dropdown-container.false {
	transform: scaleY(0);
}

#SliderNavbar
	.wrapper
	.body
	.menubar
	ul
	.dropdown-mobile
	.dropdown-container.true {
	height: 100%;
	transform: scaleY(1);
}

#SliderNavbar
	.wrapper
	.body
	.menubar
	ul
	.dropdown-mobile
	.dropdown-container
	.dropdown-box {
	margin-top: 0.25rem;
}

#SliderNavbar
	.wrapper
	.body
	.menubar
	ul
	.dropdown-mobile
	.dropdown-container
	.dropdown-box:not(:last-child) {
	margin-top: 0.5rem;
	border-bottom: 1px solid #fff;
}

#SliderNavbar
	.wrapper
	.body
	.menubar
	ul
	.dropdown-mobile
	.dropdown-container
	.dropdown-link {
	display: block;
	font-size: 1rem;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	color: #fff;
}
