.Header {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 75vh;
}

.Header .wrap-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 75vh;
  z-index: -2;
}

.Header .wrap-header-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 30%;
}

.Header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 75vh;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
  z-index: -1;
}

.Header .container {
  flex: 1;
}

.Header .content-container {
  display: flex;
  align-items: center;
}

.Header .text {
  margin-top: 0.5rem;
  color: #fff;
}

.modal-body {
  padding: 0;
}

.video-modal-frame {
  width: 100%;
  height: 400px;
}

@media all and (min-width: 992px) {
  .Header,
  .Header .wrap-header-bg,
  .Header .overlay {
    height: 80vh;
  }
}

@media (max-width: 768px) {
  .video-modal-frame {
    height: 250px;
  }
}
