.ChatContainer {
	height: 90vh;
	margin: 0px 20px;
	border-radius: 6px;
	box-shadow: 0px 8px 10px rgb(0, 0, 0, 0.25);
}

.ChatContainer .fluid-container,
.ChatContainer .row,
.ChatContainer .chat-column {
	height: 100%;
}

.ChatContainer .chat-column {
	padding: 0;
}

.ChatContainer .chat-column > * {
	height: 100%;
}

.ChatContainer .chat-users-list {
	overflow-y: scroll;
	padding: 20px 16px;
	border-right: 1px solid #ccc;
}

@media all and (max-width: 992px) {
	.ChatContainer {
		height: auto;
		margin: 0px 20px;
		border-radius: 6px;
		box-shadow: 0px 8px 10px rgb(0, 0, 0, 0.25);
	}

	.ChatContainer .fluid-container,
	.ChatContainer .row,
	.ChatContainer .chat-column {
		height: auto;
	}

	.ChatContainer .chat-users-list {
		max-height: 300px;
	}

	.ChatContainer .timeline-cloumn {
		margin: 0px 12px;
	}
}
