/* .preview-faqs .faq-block {
  border-bottom: 1px solid black;
} */

.preview-faqs .faq-block .faq-content {
  width: 94%;
  margin-left: 10px;
  
}

.faq-question {
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
}

.faq-question .question-icon {
  height: 28px;
  width: 29px;
  margin-right: 10px;
}

.faq-answer {
  background-color: var(--lightbg);
  margin-left: 15px;
}

.faq-answer p {
  margin-left: 2em;
  font-size: 14px;
  font-weight: lighter;
  padding-bottom: .45rem;
}

.faq-answer::before {
  content: "";
  height: 10px;
  width: 10px;
  position: relative;
  top: -40px;
  border-bottom: 20px solid var(--lightbg);
  border-right: 20px solid transparent;
}


.no-faqs{
  font-size: 14px;
  color: var(--darkGray);
}