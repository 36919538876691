.Faqs .Faqs-Body {
    margin-top: 40px;
    margin-bottom: 60px;
}

.Faqs .Faqs-Body .Faq-options .Option-Box {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    width: 250px;
    height: 50px;
}

.Faqs .Faqs-Body .Faq-options .Option-Box:hover {
    border-bottom: 1px solid #eb982f;
}

.Faqs .Faqs-Body .Faq-options .Option-Box.active {
    background-color: #eb982f;
}

.Faqs .Faqs-Body .Faq-Section .section-title {
    margin-bottom: 1rem;
}

.Faqs .Faqs-Body .Faq-Section:not(:first-child) .section-title {
    margin-top: 2rem;
}
