.SliderArrow {
    position: absolute;
    transform: translateX(-50%);
    cursor: pointer;
    width: 52px;
    height: 52px;
    border: 1px solid #eb982f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    transition: all 200ms ease;
    z-index: 1;
}

.SliderArrow.left {
    left: calc(50% - 32px);
}

.SliderArrow.right {
    left: calc(50% + 32px);
}

.SliderArrow:hover {
    background-color: #eb982f;
}

.SliderArrow:hover .icon {
    color: #fff;
}

.SliderArrow.disabled {
    opacity: 0.5;
}

.SliderArrow .icon {
    font-size: 1.5rem;
    color: #eb982f;
}

.SliderArrow.left .icon {
    margin-right: 4px;
}

.SliderArrow.right .icon {
    margin-left: 4px;
}
