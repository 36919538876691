.FAQSForm {
  width: 100%;
}

.about-faqs {
  font-size: 14px;
  color: var(--dashboardActive);
}

.FAQSForm .input-wrapper:not(:first-child) {
  margin-top: 1rem;
}

.FAQSForm .btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.FAQSForm .btn-wrapper span {
  margin-left: 1rem;
}

.FAQSForm .create-btn-wrapper {
  margin-top: 1rem;
}

.FAQSForm .faqs-list {
  margin-top: 2rem;
}

.FAQSForm .faqs-list .wrap-title {
  margin-bottom: 8px;
}

.FAQSForm .faqs-list .faq-item {
  margin-bottom: 1.685rem;
}

.FAQSForm .faqs-list .faq-item {
  justify-content: space-between;
}

.FAQSForm .faqs-list .faq-item button,
.create-faqbtn {
  border: none;
  color: var(--linearBlue);
  background-color: #9cdee7;
}

.FAQSForm .faqs-list .faq-item button {
  position: relative;
  left: 74%;
  font-size: 14px;
}

.create-faqbtn {
  position: relative;
  left: 20;
  margin-top: 2rem;
  font-size: 14px;
}

.FAQSForm .faqs-list .faq-item .options .wrap-option:not(:first-child) {
  margin-left: 8px;
}

.FAQSForm .faqs-list .faq-item .wrap-card {
  width: 100%;
}

.infocircle-icon {
  position: relative;
  height: 17px;
  width: 17px;
  top: 23px;
  left: 13%;
  color: var(--spanishGrey);
}

.FAQSForm .CollapseCard-Head .wrap-title {
  width: 90%;
}

.FAQSForm .CollapseCard-Head p {
  width: 100%;
  word-wrap: break-word;
}

.faqs-note {
  color: #061024;
  font-size: 14px;
  font-weight: lighter;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 2px black;
  padding: 10px;
  box-shadow: 0 2px 2px 0 #888888;
}

.faqSkip-note {
  font-size: 12px;
  color: var(--darkGray);
  text-align: center;
}
@media all and (max-width: 991px) {
  .FAQSForm .btn-wrapper {
    flex-direction: row;
    align-items: center;
  }

  .FAQSForm .btn-wrapper span {
    margin: 1rem 2px 0 4px;
  }
}

@media all and (max-width: 767px) {
  .infocircle-icon {
    transform: translateX(10px);
  }
}

@media all and (max-width: 450px) {
  .infocircle-icon {
    transform: translateX(30px);
  }
}
