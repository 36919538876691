.Contact {
  margin-top: 80px;
}

.Contact .Contact-Wrapper .Head .custom-link {
  text-decoration: underline;
  color: var(--primaryYellowColor);
}

.Contact .Contact-Wrapper .Head .text {
  width: 80%;
  color: #4f4f4f;
}

.Contact .Contact-Wrapper .Head .text-one {
  margin-top: 20px;
}

.Contact .Contact-Wrapper .Head .text-second {
  margin-top: 20px;
  margin-bottom: 30px;
}

.Contact .Contact-Wrapper .Body > * {
  margin-bottom: 30px;
}

.Contact .wrap-peopleImg {
  width: 100%;
}

.Contact .wrap-peopleImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media all and (min-width: 1200px) {
  .Contact .wrap-peopleImg {
    width: 570px;
    height: 600px;
  }
}
